import { ProgramTypes } from 'common/viewModels/MyBooking/MyBookingListViewModel'
import { UserViewModelContext } from '../UserViewModel'
import { useContext, useMemo, useState } from 'react'
import Joi from 'joi'
import { ProgramTypeKeys } from 'pageComponents/booking/BookingBase'
import { useTranslation } from 'react-i18next'

/**
  * @param {ProgramTypes} programTypes
  * @param {{
    * participantName: string,
    * age: number
  * }} initValue
 */

type  ParticipantInfoFormState = {
  participantName: string,
  age: number | null
}

type ParticipantInfoFormSchema = {
  participantName: string,
  age: number
}

const useBookingParticipantInfo = (programTypes: ProgramTypeKeys, initValue = null) => {
  const {currentUser} = useContext(UserViewModelContext)
  const {t} = useTranslation('booking')
  const getInitParticipantInfo = () => {
    if (initValue) {
      return initValue
    }
    return {
      participantName: "",
      age: null
    }
  }
  
  const [participantInfo, setParticipantInfo] = useState<ParticipantInfoFormState>(getInitParticipantInfo());

  const validateParticipantInfoResult = useMemo(() => {
   
    
    const schema = Joi.object<ParticipantInfoFormSchema>({
      participantName: Joi.string()
      .trim()
      .min(1)
      .max(100)
      .pattern(/^[^0-9]*$/)
      .required()
      .messages({
        'string.pattern.base': t("error.participantInfo.participantName.string.pattern.base"),
        'string.max': t("error.participantInfo.participantName.string.max"),
        "*":t("error.participantInfo.participantName.*")
      }),
      age: Joi.number()
      .min(0)
      .required()
      .messages({
        'number.base':  t("error.participantInfo.age.number.base"),
      
      })
    });

    return schema.validate(participantInfo, {abortEarly: false})

    
  }, [t, participantInfo]);

  const isParticipantInfoValid = participantInfo ? !validateParticipantInfoResult.error : false
  const participantInfoErrors = validateParticipantInfoResult.error ? validateParticipantInfoResult.error.details : []


  


  return {
    participantInfo,
    setParticipantInfo,
    isParticipantInfoValid,
    participantInfoErrors
  }
}

export default useBookingParticipantInfo
