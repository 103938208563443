import { IPromoCode } from "common/models/PromoCode";
import { getLocalisedString } from "common/utils/stringHelper";
import {
  IMonthlyProgrammeBookingViewModel,
  ProgrammeBookingSummary,
} from "models/Programme";
import { Voucher } from "models/Voucher";
import ReceiptDiscount from "pageComponents/booking/ReceiptDiscount";

type OrderInfoWithReceiptProps = Pick<
  ProgrammeBookingSummary,
  "programmeName" | "coaches" | "pricing" | "lessons"
> &
  React.HTMLProps<HTMLDivElement> & {
    voucher?: Voucher[];
    promoCode?: IPromoCode;
  };

import CommonTextfield from "components/common/Textfield";
import React from "react";
import { useTranslation } from "react-i18next";

const ProgramOrderInfo = ({
  lessons,
  programmeName,
  coaches,
  pricing,
  className,
  voucher,
  promoCode,
  children,
}: OrderInfoWithReceiptProps) => {
  const { t } = useTranslation("monthlyProgram");
  return (
    <div className={className}>
      <div className="font-medium">{programmeName}</div>
      {lessons.map((lesson, idx) => {
        return (
          <div key={idx}>
            {lesson.date} {lesson.startTime}-{lesson.endTime}
          </div>
        );
      })}
      <div>{t("monthlyProgramDetail.pricePerLesson", { price: pricing })}</div>
      {coaches.map((coach, idx) => {
        return (
          <div key={idx}>
            {getLocalisedString(coach.coach_name_en, coach.coach_name_zh)}
          </div>
        );
      })}
      <ReceiptDiscount
        promoCode={promoCode ?? null}
        appliedVoucher={voucher ?? []}
      />
      {children}
    </div>
  );
};

export default ProgramOrderInfo;
