import React, { useState, useEffect, useContext, useRef } from "react";
import { BrowserRouter, useLocation, useSearchParams } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import cn from "classnames";
import Route from "./Route";
import { IGlobalProviderContext, IUserViewModelContext } from "./@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  UserViewModelContext,
  UserViewModelProvider,
} from "./common/viewModels/UserViewModel";
import { navToLogin } from "./navigators/Router";
import BottomBar from "./components/BottomBar";
import GlobalProvider from "common/context/providers/globalProvider";
import ConfirmModalProvider, {
  confirmModalContext,
} from "contexts/ConfirmModalProvider";
import theme from "utils/theme";
import "dayjs/locale/zh-hk";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";
import NavBar from "components/NavBar";
import { initRefreshInterceptor } from "common/network";
import GlobalLoading from "components/common/GlobalLoading";
import ScrollToTop from "ScrollToTop";
import GlobalVerifyAccountModal from "components/common/Modal/GlobalVerifyAccountModal";
import { Helmet } from "react-helmet";
import { getLocalisedString } from "common/utils/stringHelper";
import { getLocalizedPageTitleWithPrefix, SEO_DEFAULT } from "utils/seo";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import config from "config";

const HIDE_MENU_PATH = ["auth", "register", "verification"];

function App() {
  const location = useLocation();
  const { t } = useTranslation("common");
  const [disableNavbar, setDisableNavbar] = useState(false);

  const { openModal } = useContext(confirmModalContext);
  const { globalState, globalDispatch } = useContext(
    GlobalContextStore as any
  ) as IGlobalProviderContext;
  const { logout, currentUser, updateCurrentUserToken, refreshCurrentUser } =
    useContext(UserViewModelContext) as IUserViewModelContext;
  const [searchParams, setSearchParams] = useSearchParams({
    hideMenu: "",
    token: "",
  });
  const hideMenu = searchParams.get("hideMenu"); //hide nav for app use
  const token = searchParams.get("token"); //token for app user verify account
  const logoutFuncRef = useRef(logout);
  const updateTokenFuncRef = useRef(updateCurrentUserToken);

  useEffect(() => {
    if (
      location?.pathname?.includes("auth") ||
      location?.pathname?.includes("register") ||
      location?.pathname?.includes("verification") ||
      hideMenu === "true"
    )
      setDisableNavbar(true);
    else setDisableNavbar(false);
  }, [location]);

  useEffect(() => {
    if (globalState.globalError != null) {
      openModal({
        title:
          globalState.globalError.message ?? t("error.internalServerError"),
      });
    }
    // Ignore openModal to be trigger on openModal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.globalError]);

  useEffect(() => {
    initRefreshInterceptor(
      () => {
        console.log("Interceptor Refresh failed, logging out");
        if (logoutFuncRef.current) logoutFuncRef.current();
      },
      (accessToken, refreshToken) => {
        console.log(
          "Interceptor Refresh success, updating token and user info"
        );
        refreshCurrentUser();
        if (updateTokenFuncRef.current)
          updateTokenFuncRef.current(accessToken, refreshToken);

        return {};
      }
    );
  }, []);

  // The js DOM global initRefreshInterceptor() will also create the callback function in memory when init.
  // Use useRef to make sure callback is calling the logout with latest context
  useEffect(() => {
    logoutFuncRef.current = () => {
      //skip auto logout when using app token
      if (!token && !hideMenu)
        logout(globalDispatch, t("login:session_expired"));
    };
    updateTokenFuncRef.current = updateCurrentUserToken;
  }, [logout, currentUser]);

  useEffect(() => {
    if (currentUser)
      refreshCurrentUser({
        checkIsNewUser: true,
        checkIsMobileVerified: true,
      });
  }, []);

  return (
    <div
      className={cn(
        "relative min-h-screen overflow-hidden bg-app-cyan",
        disableNavbar ? "" : "pb-[330px] md:pb-[75px]"
      )}
    >
      {!location?.pathname?.includes("auth") && (
        <GlobalLoading loading={globalState.globalLoading} />
      )}
      {!disableNavbar && <NavBar />}
      {/* full height - topbar - bottom */}
      <div className="min-h-[calc(100vh-100px-402px) mb:min-h-[calc(100vh-185px-165px)]">
        <Route />
      </div>
      {!disableNavbar && <BottomBar />}
      <GlobalVerifyAccountModal />
    </div>
  );
}
const AppWrapper = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{getLocalizedPageTitleWithPrefix()}</title>
        <meta
          name="description"
          content={
            i18n.language === "en"
              ? SEO_DEFAULT.en.description
              : SEO_DEFAULT.zh.description
          }
        />
      </Helmet>
      <GlobalProvider>
        <ConfirmModalProvider>
          <SnackbarProvider maxSnack={3}>
            <UserViewModelProvider onLogout={navToLogin}>
              <BrowserRouter>
                <GoogleReCaptchaProvider
                  reCaptchaKey={
                    !config.RECAPTCHA_SITE_KEY_CHECKBOX
                      ? config.RECAPTCHA_SITE_KEY
                      : ""
                  }
                  useEnterprise={true}
                >
                  <App />
                  <ScrollToTop />
                  <div id="ss-modal-portal" />
                </GoogleReCaptchaProvider>
              </BrowserRouter>
            </UserViewModelProvider>
          </SnackbarProvider>
        </ConfirmModalProvider>
      </GlobalProvider>
    </>
  );
};

export default AppWrapper;
