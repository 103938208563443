import { authedInstance, instance } from ".";
import { Facility } from "../models/Facility";
import { FacilityCategory } from "../models/FacilityCategory";
import { GolfMap } from "../models/GolfMap";
import { Timeslot } from "../models/Timeslot";
import { Venue } from "../models/Venue";
import { isEmptyValues } from "../utils/common";

export async function listFacilityCategory({ module }) {
  const res = await authedInstance.get(
    "/v1/app/facility/category?" + new URLSearchParams({ module }).toString()
  );
  console.log("List Facility Category: ", res.data);
  return res?.data;
}

export async function listFacility() {
  const res = await instance.get("/v1/app/discover/facility");
  console.log("List Facility: ", res?.data?.result);
  return res?.data;
}

export async function listFacilityByCategory({ categoryId }) {
  const res = await instance.get(`v1/app/facility/category/${categoryId}`);
  console.log("List Facility By Category: ", res.data);
  return res?.data;
}

// Get Facility Detail for discover
export async function getFacilityById({ facilityId }) {
  const res = await instance.get(
    `/v1/app/discover/facility/${facilityId}/detail`
  );
  console.log("Get Facility by Id: ", res?.data?.result);
  return res?.data;
}

// Get Facility Detail
export async function getFacilityDetail({ facilityId }) {
  const res = await instance.get(`/v1/app/facility/${facilityId}`);
  console.log("Get Facility Detail: ", res.data);
  return res?.data;
}

export async function listVenueByFacility({ facilityId, ref_number = null }) {
  let params;
  if (ref_number) {
    params = {
      params: {
        ref_number,
      },
    };
  }
  const res = await instance.get(`v1/app/facility/${facilityId}/venue`, params);
  // console.log("List Venue By Facility: ", res.data);
  return res.data;
}

export async function getVenueDetail({ venueId }) {
  const res = await instance.get(`v1/app/facility/venue/${venueId}`);

  return res.data;
}

export async function getGolfMapByFacility({ facilityId }) {
  console.log("Get Golf Map by : ", facilityId);
  const res = await instance.get(`v1/app/facility/${facilityId}/golf`);
  console.log("Get Golf Map: ", res.data.result.maps);

  return res.data;
}

export async function getTimetableByFacility({ facilityId, selectedDate }) {
  console.log("Get timetable: ", selectedDate);
  const res = await instance.get(
    `v1/app/facility/${facilityId}/timetable?${new URLSearchParams({
      selected_date: selectedDate,
    }).toString()}`
  );
  console.log("Get Facility Timetable: ", res?.data?.result);
  return res?.data;
}

export async function getFacilityTimeslots({
  facilityId,
  venueId = null,
  date,
}) {
  console.log(venueId, !isEmptyValues(venueId));
  const res = !isEmptyValues(venueId)
    ? await getFacilityVenueTimeslots({ facilityId, venueId, date })
    : await getFacilityAutoAssignVenueTimeslots({ facilityId, date });

  return res;
}

export async function getFacilityVenueTimeslots({ facilityId, venueId, date }) {
  console.log("Get Venue Timeslots by date: ", facilityId, venueId, date);
  const res = await authedInstance.get(
    `v1/common/booking/${facilityId}/${venueId}/${date}/timeslot`
  );

  console.log("Get Venue Timeslots by date: ", res.data);
  return res.data;
}

export async function getFacilityAutoAssignVenueTimeslots({
  facilityId,
  date,
}) {
  console.log(`/v1/common/autoassign/timeslot/${facilityId}/${date}`);
  const res = await authedInstance.get(
    `/v1/common/autoassign/timeslot/${facilityId}/${date}`
  );

  console.log("Get Facility Auto Assign Venue Timeslots: ", res.data);
  return res.data;
}
