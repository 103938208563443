import LoginBackground from "components/LoginBackground/LoginBackground";
import cn from "classnames";
import Card from "components/common/Card";
import LogoCard from "components/common/cards/LogoCard";
import { useTranslation } from "react-i18next";
import GeneralButton from "components/common/Button";
import { Divider } from "@mui/material";
import Typography from "components/common/Typography";
import Space from "components/common/Space";
import Styled from "./Styled";
import { WindowSize } from "utils/responsive";
import Home from "./Components/Login/Home";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import MobileLogin from "./Components/Login/MobileLogin";
import EmailLogin from "./Components/Login/EmailLogin";
import { PageSearchParamsKey } from "./LoginScreen";

export enum loginMethod {
  "EMAIL" = "email",
  "MOBILE" = "mobile",
}

const LoginHome = () => {
  const [searchParams, setSearchParams] = useSearchParams({ method: "" });
  const method = searchParams.get(PageSearchParamsKey.method);

  const _renderLoginMethod = () => {
    switch (method) {
      case loginMethod.MOBILE:
        return <MobileLogin />;
      case loginMethod.EMAIL:
        return <EmailLogin />;
      default:
        return <Home />;
    }
  };

  return _renderLoginMethod();
};

export default LoginHome;
