import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import dayjs from "common/utils/dayjs";
import { useEventViewModel } from "common/viewModels/EventViewModel";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import { getLocalisedString } from "common/utils/stringHelper";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import {
  IEventViewModel,
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import IconLoader from "../../assets/IconLoader";
import Styled from "./Styled";
import PageHeader from "components/common/PageHeader";
import TncCheckbox from "components/common/checkbox/TncCheckbox";
import SimpleTextModal from "components/common/Modal/SimpleTextModal";
import PageContainer from "components/common/PageContainer";
import GeneralButton from "components/common/Button";
import IconClock from "assets/ic-clock.png";
import IconLocation from "assets/ic-location.png";
import IconPrice from "assets/ic-price.png";
import { removeHtmlTag } from "utils/common";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";
import useLoginRedirect from "hooks/useLoginRedirect";

const SlotItem: React.FC<{ slots: string[] }> = ({ slots }) => {
  return (
    <ul>
      {_.map(slots, (slot, i) => {
        return <li key={i}>{slot}</li>;
      })}
    </ul>
  );
};

const EventDetailPage = () => {
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const { t } = useTranslation("common");

  const { currentUser, refreshCurrentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { currentEvent, getEventDetail, getPendingPaymentStatus } =
    useEventViewModel() as IEventViewModel;
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const { withAuthCheck } = useLoginRedirect();

  const [isAgree, setIsAgree] = useState<boolean>(false);

  const formattedDateList = useMemo(() => {
    if (currentEvent == null) {
      return [];
    }
    return currentEvent.event_date.map((timeSlot) => {
      const startDate = dayjs(timeSlot?.start_date);
      const endDate = dayjs(timeSlot?.end_date);
      const dateString =
        startDate.tz().format("DD/MM/YYYY, HH:mm - ") +
        endDate.tz().format("HH:mm");

      return dateString;
    });
  }, [currentEvent]);

  const isDisplayBanner = useMemo(() => {
    return (
      getLocalisedString(
        currentEvent?.banner_en,
        currentEvent?.banner_tc,
        false
      ) != null
    );
  }, [currentEvent]);

  /**
   * Ref: https://gitlab.zensis.com/saisha/saisha-client/blob/develop/src/components/screens/events/EventDetailScreen.js#L47
   */
  const sportPassString = useMemo(() => {
    const sportPassTypeMap = {
      ANNUAL: t("event:annual"),
      MONTHLY: t("event:monthly"), // api return monthly when target day pass
      DAY: t("event:day"),
      VERIFIED: t("event:allVerifiedUser"),
    };

    if (currentEvent?.registration_target == null) return "";

    return Array.isArray(currentEvent?.registration_target)
      ? currentEvent?.registration_target
          ?.map((type) => sportPassTypeMap[type])
          .filter((item) => item != null)
          .join(", ")
      : sportPassTypeMap[currentEvent?.registration_target] ?? null;
  }, [currentEvent?.registration_target, t]);

  useEffect(() => {
    if (eventId != null) {
      getEventDetail(eventId, globalDispatch).then((res) => {
        if (!res?.success) navigate("../");
      });
    }
  }, []);

  const handleSubmit = async () => {
    const res = await refreshCurrentUser({
      checkIsNewUser: true,
    });
    const isPendingPayment = await getPendingPaymentStatus(eventId as string);
    if (res?.success && res?.isNewUser === false && isPendingPayment?.success) {
      navigate(`../booking/${eventId}`, {
        state: { item: currentEvent },
      });
    }
  };

  const getMetaDescription = () => {
    const description = getLocalisedString(
      currentEvent?.desc_en,
      currentEvent?.desc_tc
    );
    return removeHtmlTag(description);
  };

  return (
    <>
      <Helmet>
        <title>
          {getLocalizedPageTitleWithPrefix({
            en: currentEvent?.title_en,
            tc: currentEvent?.title_tc,
          })}
        </title>
        <meta name="description" content={getMetaDescription()} />
      </Helmet>
      <PageContainer
        banner={getLocalisedString(
          currentEvent?.banner_en,
          currentEvent?.banner_tc
        )}
        title={getLocalisedString(
          currentEvent?.title_en,
          currentEvent?.title_tc
        )}
      >
        <div className="flow-root">
          <Styled.EventDetailContent>
            <Styled.Title>
              {getLocalisedString(
                currentEvent?.title_en,
                currentEvent?.title_tc
              )}
            </Styled.Title>
            <Styled.Paragraph>
              <div
                dangerouslySetInnerHTML={{
                  __html: getLocalisedString(
                    currentEvent?.desc_en,
                    currentEvent?.desc_tc
                  ),
                }}
              />
            </Styled.Paragraph>
            <Styled.Paragraph>
              <div className="flex flex-col">
                <p className="text-app-orange font-medium">{`${t(
                  "event:detail.availableOn"
                )}`}</p>
                <p>{`${sportPassString}`}</p>
              </div>
            </Styled.Paragraph>
            <Styled.Paragraph
              style={{
                marginBottom: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Styled.DetailIcon>
                <img src={IconClock} />
              </Styled.DetailIcon>
              <div className="p-[10px]">
                <SlotItem slots={formattedDateList} />
              </div>
            </Styled.Paragraph>
            <Styled.Paragraph>
              <Styled.DetailIcon>
                <img src={IconLocation} />
              </Styled.DetailIcon>
              <Styled.DetailItem style={{ lineHeight: "45px" }}>
                {getLocalisedString(
                  currentEvent?.venue_en,
                  currentEvent?.venue_tc
                )}
              </Styled.DetailItem>
            </Styled.Paragraph>
            <Styled.Paragraph>
              <Styled.DetailIcon>
                <img src={IconPrice} />
              </Styled.DetailIcon>
              <Styled.DetailItem style={{ lineHeight: "45px" }}>
                {currentEvent == null
                  ? "-"
                  : t("common:price", {
                      price: currentEvent.price,
                    })}
              </Styled.DetailItem>
            </Styled.Paragraph>
            <Styled.Hr />

            <Styled.Paragraph>
              {t("event:remarks")}:
              <br />
              {getLocalisedString(
                currentEvent?.remarks_en,
                currentEvent?.remarks_tc
              )}
            </Styled.Paragraph>
            {currentEvent != null && (
              <div className="float-left">
                <TncCheckbox
                  id="tnc"
                  name="tnc"
                  value={isAgree}
                  data={currentEvent.t_and_c}
                  onChange={() => setIsAgree(!isAgree)}
                />
              </div>
            )}
          </Styled.EventDetailContent>

          <Styled.Paging>
            <GeneralButton
              disabled={!isAgree || currentEvent?.isFull}
              onClick={withAuthCheck(handleSubmit)}
            >
              {currentEvent?.isFull ? t("event:soldOut") : t("event:enroll")}
            </GeneralButton>
          </Styled.Paging>
        </div>
      </PageContainer>
    </>
  );
};

export default EventDetailPage;
