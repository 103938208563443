import { getLocalisedString } from "common/utils/stringHelper";
import Styled from "./Styled";
import Typography from "components/common/Typography";

const Section1 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString("Definitions", "定義")}
      <div className="ml-[15px] mb-[10px]">
        <Typography fontWeight="400">
          {getLocalisedString(
            "Unless otherwise defined, these terms shall have the following meanings:",
            "除非另有定義，本條款中的術語應具有以下含義："
          )}
        </Typography>
      </div>
      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "“GO PARK Sports” (GPS / The Facility) is a sports academy operated by Inno-Sports Concept Management Limited (Inno-Sports) located at 9 Hoi Ying Road, Tai Po, New Territories, Hong Kong.",
            "「GO PARK Sports」（GPS/設施）是由Inno-Sports Concept Management Limited (Inno-Sports) 經營的體育學院，位於香港新界大埔海映路9號。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "The Mobile Application (The App) is the official mobile application for GO PARK Sports, used to register for a user account and book services.",
            "「手機應用程式」（應用程式）是 GO PARK Sports 的官方手機應用程式，用於註冊用戶帳戶和預訂服務。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Registered User (User): An individual who has registered and been verified within the App.",
            "「註冊用戶」（用戶）：在應用程式中已註冊並驗證的個人。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

const Section2 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString("Registered Users", "註冊用戶")}
      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "All customers must first register as user of GPS through the App or the Kiosk at GPS by providing the required information.",
            "所有顧客必須首先通過應用程式和設施內的電子自助服務機器註冊成為GPS的用戶，並提供所需資料。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "The registered name of the User must be the same as the one stated on their official identification document such as Hong Kong Identity Card, Passport or Birth Certificate.",
            "用戶的註冊名稱必須與其官方身份證明文件（如香港身份證、護照或出生證明書）上的名稱相同。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Customers who are under the age of 18 must obtain consent from their parent or legal guardian before registering as a User of GPS.",
            "18歲以下的顧客在註冊成為GPS的用戶之前，必須獲得其父母或法定監護人的同意。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Users must register a correct and valid mobile number.",
            "用戶必須註冊一個正確且有效的手機號碼。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Users must register an email address that they frequently use.",
            "用戶必須註冊他們經常使用的電子郵件地址。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "GPS take no responsibility for any undelivered SMS, push notifications or emails.",
            "GPS對任何未送達的短信、推送通知或電子郵件概不負責。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Users must ensure that the information submitted on the registration form is authentic, accurate, complete, not misleading and without any elements of fraud.",
            "用戶必須確保在註冊表格上提交的信息是真實、準確、完整、不具誤導性且不含任何欺詐成分。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Welcome offer(s) (if any) is applicable to Users upon successful registration and verification. Each User is entitled to the welcome offer once only. GPS shall have the absolute discretion to determine the User’s entitlement to the welcome offer based on the User’s transaction records with via the App.",
            "如有提供，迎新優惠適用於用戶成功註冊並驗證後。每個用戶僅限享受一次迎新優惠。GPS將根據用戶通過應用程式的交易記錄，全權酌情決定用戶對迎新優惠的資格。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "No welcome offer (if any) will be given to any Users’ whose registration with GPS has been cancelled and/or unsuccessful and/or suspended for whatever reason.",
            "對於任何因各種原因取消、未成功或被暫停註冊的用戶，將不延長迎新優惠的期限（如有）。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "GPS reserves the sole and absolute right and discretion to determine a person’s eligibility to become a user of GPS and forfeit the welcome offer without prior notice.",
            "GPS保留全權和絕對自主權，確定個人成為GPS用戶的資格並在不事先通知的情況下取消歡迎優惠。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "All information submitted for registration must be correct and valid. A virtual mobile number is not acceptable for registration. GPS shall have the right to, without any prior notice, immediately (i) suspend such users’ rights of access and booking; (ii) forfeit all the pre-paid payments; and (iii) cancel the user’s rights of redemption of per-paid purchases.",
            "所有提交註冊的資料必須是正確且有效的。不接受虛擬手機號碼進行註冊。GPS有權在不事先通知的情況下立即（i）暫停該用戶的使用和預訂權利；（ii）沒收所有預付款項；以及（iii）取消用戶對預付購買的兌換權利。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "If users have changed all information provided their mobile number, they must inform GPS immediately. If it has been identified that users have registered with a mobile number that they do not own legitimately, GPS reserves the right to suspend the user in question. In such cases, all unredeemed vouchers, purchases, and bookings in the user’s account will be forfeited without any prior notice. GPS will not be held liable for or responsible for compensating the user in any manner for any loss or damage due to any unauthorised access to the App for whatever reason including stolen or lost mobile devices and/or failure to inform GPS after a change of the registered mobile number.",
            "如果用戶更改了提供的所有資料，包括手機號碼，必須立即通知GPS。如果確定用戶以他們無權擁有的手機號碼註冊，GPS保留暫停該用戶資格的權利。在這種情況下，用戶帳戶中所有未兌換的優惠券、購物和預訂將被沒收，恕不另行通知。對於由於任何原因（包括手機丟失或遺失和/或未在變更註冊手機號碼後通知GPS）導致未經授權進入應用程式而造成的任何損失或損害，GPS將不承擔賠償責任。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Upon successful registration and verification, users who wish to rectify their registered information send an email to enquiry@goparksports.hk. Changes of this nature will only be processed upon submission of supporting documents that verify the requested update to personal information.",
            "用戶成功註冊和驗證後，如果希望更改其註冊資料，應通過電子郵件向enquiry@goparksports.hk發出請求。只有在提交證明所需個人資料更新的文件後，才會處理此類更改。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "User accounts, purchases and bookings are non-transferable.",
            "用戶帳戶、購買和預訂均不可轉讓。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Users should double-check their purchase and booking records and details after any updates. Except for manifest errors, all user data, purchases and booking records kept by GPS are final and conclusive.",
            "用戶應在任何更新後仔細檢查其購買和預訂記錄及詳情。除了明顯錯誤外，GPS保存的所有用戶數據、購買和預訂記錄均為最終和決定性的。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Users are required to maintain strict confidentiality and security of their User ID, Usernames, passwords, and email addresses. Users are fully responsible for any unauthorized use of their accounts, whether with or without their authorization, by any individual, including third parties. GPS will not be held responsible for any loss or damage incurred.",
            "用戶必須嚴格保密其用戶ID、用戶名、密碼和電子郵件地址。用戶應對其帳戶的任何未經授權使用負全部責任，無論是否經過其授權，包括任何第三方的使用。對於因此引起的任何損失或損害，GPS概不負責。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Staff at GPS are authorized to request users to present a valid identification document to verify the accuracy of user data displayed on their mobile devices.",
            "GPS的工作人員有權要求用戶出示有效的身份證明文件，以驗證其在移動設備上顯示的用戶數據的準確性。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "GPS reserve the right to suspend any users for 3 months without any prior notice if a user uses or access without authority any accounts that belong to another person or is found to be dishonest providing false information in his/her application or be dishonest in exercising of user rights and privileges.",
            "若用戶未經授權使用或使用屬於他人的帳戶，或在申請中提供虛假資訊或在行使用戶權利及特權時不誠實，GPS保留在不事先通知的情況下暫停任何用戶的權利。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Users can choose to delete their account by sending an email to enquiry@goparksports.hk. The content must include the User ID, Username, registered mobile number, email address used for identity verification and clearly state the intention to delete account. The account deletion request will be processed within forty (40) days. Upon account deletion or any other termination of users, any unredeemed vouchers, purchases and bookings in the users’ account will be forfeited without further notice.",
            "用戶如需選擇刪除帳戶，可以通過發送電子郵件至 enquiry@goparksports.hk，內容必須包括用戶ID、用戶名、註冊手機號碼、用於身份驗證的電子郵件地址，並明確表示刪除賬戶的原因。刪除帳戶請求將在40天內處理。一旦賬戶刪除或任何其他形式的用戶暫停，用戶帳戶中所有未兌換的優惠券、購買、預訂將被沒收，恕不另行通知。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

const Section3 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString(
        "Operational Hours, Access to Facility and Rights of Use",
        "營運時間、設施使用與使用權"
      )}

      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "Available booking slots for GPS facilities will be displayed on The App. Operating hours may vary between facilities and are subject to change. Users should check The App for the most up-to-date hours of operation.",
            "所有設施可預約時段將在手機應用程式顯示。各設施的營業時間可能有差異，並可能因應情況改變。用戶應查閱手機應用程式，獲取最新營業時間資訊。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Regular class, coaching, events, elite training, community sports functions have priority over social bookings.",
            "運動課程、教練、活動、精英訓練、社區體育活動功能將優先於一般預訂。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Each user must individually register an account. Sharing or transferring of accounts are strictly prohibited.",
            "每位用戶必須分別註冊。嚴禁共享或轉讓帳戶。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Adult guardians of users under 18 must be registered to become a Register User on The App and obtain verification from GPS management.",
            "18歲以下用戶的成人監護人必須在應用程式上註冊成為註冊用戶並獲得GPS管理層的驗證。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "All users are required to vacate the booked facility upon the completion time of their booking. Failure to do so may result in additional charges.",
            "所有用戶在預訂時間結束時必須離開預訂的設施。未能如期離開可能導致額外收費。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Only GPS registered instructors are authorized to conduct lessons within the GPS facility. Users breaching the rules will be asked to leave the facility premises immediately.",
            "只有經過GPS註冊的教練方可在GPS設施內進行教學。違反此規定的用戶將被要求立即離開設施。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Smoking and/or vaping is strictly prohibited within all GPS facilities. Users or individuals that are found smoking/vaping within the GPS premises will be asked to leave immediately and their rights to access the facilities may be rejected.",
            "嚴禁在GPS所有設施內吸煙及/或使用電子煙。一經發現有此行為，相關人士將被要求立即離開，並可能會被暫停使用設施的權利。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "All individuals utilizing the facility must conduct themselves in a respectful manner and comply with instructions from staff. GPS staff have the right to reject any individual’s access to the facility without refund or warning if rules are violated. Repeat or serious offenders may be banned from the facility for a period of up to 3 months. The final decision on lifting any ban will be at the discretion of GPS management.",
            "所有進入設施的人士必須以尊重的態度進行，遵守工作人員的指示。GPS工作人員有權拒絕違規者進入設施而不提供退款或預警。重複或嚴重違規者將禁止進入設施長達3個月。最終解除禁令決定權由GPS管理層全權決定。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "Any activities that is deemed unauthorized or disturbance to other users by GPS Management is strictly prohibited. Individuals involved may be asked to leave GPS premises.",
            "任何被 GPS 管理認為未經授權或擾亂其他使用者的活動均被嚴格禁止。相關人士可能會被要求離開 GPS 設施。"
          )}
        </Styled.ListItem>
        <Styled.ListItem>
          {getLocalisedString(
            "All users utilizing all facilities hereby agree to the Terms & Conditions of GPS as stated here as well as the Rule & Regulations and/or Bye-Laws applicable to individual facilities and Third-Party Vendors. Conduct that violates Hong Kong laws, the Terms and Conditions, Rules & Regulations and/or Byelaws will result in immediate access termination without compensation or appeal.",
            "所有使用設施的用戶均需同意遵守此處所述的GPS條款及細則，以及適用於個別設施和第三方供應商的規則及/或章程。任何違反香港法律、條款及細則、規則及/或章程的行為，都將導致立即暫停進入設施的權利，且不予賠償或上訴。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

const Section4 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString("Booking & Cancellation Policy", "預訂及取消政策")}

      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "All facility bookings are open to all registered users to book via The App fourteen (14) days prior on a first-come-first-served basis.",
            "所有設施的預訂向所有註冊用戶開放，用戶可以透過應用程式提前十四天（14）進行預訂，採先到先得的方式。"
          )}
        </Styled.ListItem>
        <Styled.ListItem tmp-order="4.2">
          {getLocalisedString(
            "Users can cancel facility bookings and private coaching lessons via The App based on the below policies:",
            "用戶可根據以下政策透過應用程式取消設施預訂和私人教練課程："
          )}
          <Styled.OrderList>
            <Styled.ListItem tmp-order="4.2.1">
              {getLocalisedString(
                "Facility bookings and private coaching cancelled with forty-eight (48) hours or more notice is eligible for refund in full in the form of a single voucher, which will be added to the User’s account on The App within thirty (30) days. Vouchers can only be used for the specific type of booking originally reserved for. The voucher expiry date is ninety (90) days from the date of issue.",
                "預訂時間前四十八（48）小時或以上取消，將有資格獲得全額退款，以同單一代金券形式在三十（30）天內添加至用戶在應用程式上的帳戶。代金券只能用於最初預訂的特定類型。代金券的有效期限為發出代金券起九十（90）天內。"
              )}
            </Styled.ListItem>
            <Styled.ListItem tmp-order="4.2.2">
              {getLocalisedString(
                "Facility bookings and private coaching cancelled with less than forty-eight (48) hours’ notice will not be refunded.",
                "預訂時間前少於四十八（48）小時取消，則不予退款。"
              )}
            </Styled.ListItem>
          </Styled.OrderList>
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.3">
          {getLocalisedString(
            "All regular classes and event enrolments are final upon payment and cannot be cancelled or no make-up be arranged.",
            "所有團體課程和活動報名一經付款即確認，不可取消或更改。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.4">
          {getLocalisedString(
            "Regular classes cancellation due to insufficient enrollment will be refunded in full in form of a single voucher, which will be added to the User’s account on The App within thirty (30) days. Notification of lesson cancellation will be done by GPS staff at least 2 days before lesson day. The voucher can be used for future regular class . The voucher expiry date is ninety (90) days from the date of issue.",
            "因報名人數不足而取消團體課程，所有預訂將全額退款，以單一代金券形式在三十（30）天內添加至用戶在應用程式上的帳戶。GPS職員將在課程前至少兩天通知報名者課程將被取消。代金券僅可用於報名團體課程報名。代金券的有效期限為發出代金券九十（90）天內。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.5">
          {getLocalisedString(
            "Cancellations due to sickness for regular classes, private lessons and event will only be refunded should the appropriate documentation be uploaded to The App within forty-eight (48) hours of the lesson time and approved by the GPS Management. Refund in full in form of a single voucher will be added to the User’s account on The App within thirty (30) days.  Vouchers can only be used for the corresponding regular class at the time of registration.The voucher expiry date is ninety (90) days from the date of issue.",
            "因病取消團體課程和私人課程，只有在課程時間後四十八（48）小時內上傳相關證明文件至應用程式並獲得GPS管理層批准，方可獲得退款。以單一代額金券形式在三十（30）天內添加至用戶在應用程式上的帳戶。代金券僅可用於報名時相應的課程。代金券的有效期限為發出代金券起九十（90）天內。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.6">
          {getLocalisedString(
            "In the event of inclement weather, all regular classes and private lessons will be canceled. GPS will notify users at least two hours prior to the class regarding the cancellation. Refund in full form of a single voucher will be added to the User’s account on The App within thirty (30) days.  Vouchers can only be used for the corresponding regular class at the time of registration. The voucher expiry date is ninety (90) days from the date of issue.",
            "若GPS因包括但不限於惡劣天氣、設施維修或活動等原因主動取消預訂，所有預訂將全額退款，以單一代金券形式在三十（30）天內添加至用戶在應用程式上的帳戶。代金券僅可用於設施預訂或課程報名時相應的課程，例如因惡劣天氣而取消團體課程，現金券僅限於預訂團體課程、相關措施適用於私人教學課程或活動。代金券的有效期限為發出代金券起九十（90）天內。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.7">
          {getLocalisedString(
            "In the case of booking cancellation initiated by GPS for reasons including but not limited to inclement weather, facility maintenance, and events, all bookings will be refunded in full in the form of a single voucher and the voucher will be added to the User’s account on The App within thirty (30) days. Vouchers can only be used for the corresponding regular class at the time of registration. The voucher expiry date is thirty (30) days from the date of issue.",
            "在惡劣天氣或天雨的情況下，所有團體課程、私人教練課程或被取消， GPS 將在課程開始前至少兩小時通知用戶有關取消的消息。代金券僅可用於設施預訂或課程報名時相應的課程，例如因惡劣天氣而取消團體課程，現金券僅限於預訂團體課程、相關措施適用於私人教學課程或活動。代金券的有效期限為發出代金券起九十（90）天內。"
          )}
        </Styled.ListItem>
        <Styled.ListItem tmp-order="4.8">
          {getLocalisedString(
            "All regular classes, private lessons and events have different enrolment periods. It is advised that interested Users to refer to the Terms & Conditions of each specific regular classes, lesson, or event. The voucher expiry date is ninety (90) days from the date of issue.",
            "所有團體課程、私人教練課程和活動均有不同的報名期限。建議有興趣的用戶參閱每個特定課程、課程或活動的條款及細則。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.9">
          {getLocalisedString(
            "All bookings for regular classes, coach lessons, and events are available on The App on a first-come-first-served basis, subject to availability.",
            "所有團體課程、私人教練課程和活動的預訂均可通過應用程式按先到先得的原則進行，視庫存情況而定。"
          )}
          <Styled.OrderList>
            {/* TODO: updated to use custom-ident value */}
            <Styled.ListItem tmp-order="4.9.1">
              {getLocalisedString(
                `All regular classes are open to all registered users to book via The App on a first-come-first-serve basis under the instructions below:`,
                `所有每月計劃均對所有註冊用戶開放，根據以下指示以先到先得的方式通過應用程式預訂：`
              )}
              <Styled.OrderList style={{ marginLeft: "20px" }}>
                <li style={{ listStyleType: "none" }}>
                  (1){" "}
                  {getLocalisedString(
                    "Twenty-eight (28) days prior for those who are currently joining regular classes;",
                    "目前參加每月計劃的用戶可提前二十八（28）天預訂；"
                  )}
                </li>
                <li style={{ listStyleType: "none" }}>
                  (2){" "}
                  {getLocalisedString(
                    "Twenty-five (25) days prior for waitlisted users;",
                    "候補用戶可提前二十五（25）天預訂；"
                  )}
                </li>
                <li style={{ listStyleType: "none" }}>
                  (3){" "}
                  {getLocalisedString(
                    "Twenty-one (21) days for other users.",
                    "其他用戶可提前二十一（21）天預訂。"
                  )}
                </li>
              </Styled.OrderList>
            </Styled.ListItem>
            <Styled.ListItem tmp-order="4.9.2">
              {getLocalisedString(
                "All private lessons are open to all registered users to book via The App twenty-one (21) days prior on a first-come-first-served basis.",
                "所有私人課程均對所有註冊用戶開放，允許他們在應用程式上提前二十一（21）天預訂，採取先到先得的方式。"
              )}
            </Styled.ListItem>
          </Styled.OrderList>
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.10">
          {getLocalisedString(
            "All bookings (facility, regular classes, private lesson & event) are only confirmed once the payment is processed and confirmed in full.",
            "所有預訂（包括設施、團體課程、教練課程和活動）僅在付款全額處理並確認後才算確認。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.11">
          {getLocalisedString(
            "GPS Management reserves the right to reject or cancel bookings, limit the number of users accessing the venue, or refuse entry to any individual based on health considerations or other factors that the management deems relevant.",
            "GPS管理層保留拒絕或取消任何預訂的權利，也可能基於健康考量或其他管理層認為相關的因素，限制某個人進入場地或拒絕其入場。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.12">
          {getLocalisedString(
            "In the event of inclement weather, GPS facilities will be closed for the safety of all users, staff members and third-party vendors. For closure and reopening details, please refer to the latest announcements on the App.",
            "若遇惡劣天氣，GPS設施將為了所有用戶及工作人員的安全而關閉。關閉和重新開放的具體詳情，請查看應用程式上的最新公告。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.13">
          {getLocalisedString(
            "User’s bookings affected by inclement weather, please refer to the below policies:",
            "若用戶預訂受到惡劣天氣影響，可根據以下政策行事："
          )}
          <Styled.OrderList>
            <Styled.ListItem tmp-order="4.13.1">
              {getLocalisedString(
                "Monthly Programs, Private Lessons, and Events: GPS and its third-party vendors will decide if lessons or events are to continue in inclement weather.",
                "團體課程、私人教練課程和活動：GPS及其第三方供應商將決定在惡劣天氣下是否繼續進行。"
              )}
            </Styled.ListItem>
            <Styled.ListItem tmp-order="4.13.2">
              {getLocalisedString(
                "Facility Bookings: Users can request free cancellation and a full refund for facility bookings during inclement weather via email to enquiry@goparksports.hk. Each case will be determined on a case-by-case basis.",
                "設施預訂：用戶可在惡劣天氣期間透過電子郵件至enquiry@goparksports.hk申請免費取消並全額退款。每個案例將根據具體情況進行評估。"
              )}
            </Styled.ListItem>
          </Styled.OrderList>
        </Styled.ListItem>

        <Styled.ListItem tmp-order="4.14">
          {getLocalisedString(
            "GPS has the right to amend the Booking & Cancellation Policy at our sole discretion without prior notice.",
            "GPS保留在未經預先通知的情況下修改預訂及取消政策的權利。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

const Section5 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString("Payment on App", "應用程式上的付款")}

      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "Users must use the eligible Payment Methods provided by GPS for online payment in each purchase and/or booking.",
            "用戶必須使用GPS提供的合格支付方式進行每次購買和/或預訂的在線支付。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS shall have the right to offer additional payment methods and/or remove existing payment methods at any time at our sole discretion. All payment shall be processed by third party payment service provider(s). Users agree that respective credit card / payment information will be stored with the third-party payment service provider(s) upon confirmation of purchase, and payment.",
            "GPS有權在任何時候自行決定提供額外的支付方式和/或取消現有的支付方式。所有支付將通過第三方支付服務提供商處理。用戶同意在確認購買和付款後，相關信用卡/支付信息將儲存於第三方支付服務提供商。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Users must ensure that they have sufficient funds on their credit card or any related wallet to fulfil payment for a purchase. GPS and the participating Merchants/Suppliers will not bear any responsibility in the event of payment failure due to insufficient funds.",
            "用戶必須確保他們的信用卡或任何相關電子錢包上有足夠的資金來完成購買。若因資金不足導致支付失敗，GPS及參與的商家/供應商將不承擔任何責任。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

const Section6 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString(
        "‘Biometric Authentication’ Function",
        "生物識別功能"
      )}

      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "Users may login to The App using “Biometric Authentication” function.",
            "用戶可以使用「生物識別」功能登錄應用程式。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            'The use of the “Biometric Authentication” function to login to The App and make purchases and bookings is subject to the terms and conditions set out in this section. By registering and activating the “Biometric Authentication” function in the App with your mobile phone or mobile device ("Designated Device"), you will be regarded as having accepted and will be bound by these terms and conditions. If you do not accept these terms and conditions, please do not register and activate the “Biometric Authentication” function in the App with any Designated Device.',
            "使用「生物識別」功能進行應用程式的登入及進行購買和預訂，需遵守本節所設定的條款及條件。您在應用程式中註冊並啟動「生物識別」功能，並與您的手機或移動裝置（「指定裝置」）一起使用時，即表示您接受並同意受這些條款及條件的約束。如果您不接受這些條款及條件，請勿在任何指定裝置上註冊或啟動應用程式中的「生物識別」功能。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "The “Biometric Authentication” function is currently available on Designated Devices: (1) Apple iPhone operating on iOS version 14.0 or above and (2) Android mobile device operating on Android OS version 11.0 or above.",
            "「生物識別」功能目前適用於以下指定裝置：（1）運行iOS 14.0或以上版本的Apple iPhone；（2）運行Android OS 11.0或以上版本的Android移動裝置。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Authentication using fingerprint is available on: (1) Apple iPhone 5s or later models with Touch ID function and operating on iOS 14.0 or above and (2) Android mobile devices operating on Android OS version 11.0 or above with compatible fingerprint sensor and recognized Trusted Execution Environment (TEE) enabled.",
            "指紋識別功能適用於：（1）配備Touch ID功能且運行iOS 14.0或以上版本的Apple iPhone 5s或更新型號；（2）配備相容指紋感應器且啟用了認可的可信執行環境（TEE）的運行Android OS 11.0或以上版本的Android移動裝置。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Authentication using facial map (which is facial recognition) is available on Apple iPhone X or later models with Face ID function and operating on iOS 14.0 or above.",
            "臉部識別（即面部識別）功能適用於配備Face ID功能且運行iOS 14.0或以上版本的Apple iPhone X或更新型號。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "When you use “Biometric Authentication” function to login to the App and redeem rewards, you are using the fingerprint and facial map (as applicable) stored on your Designated Device to authenticate your identity, instead of the account password. The App will access the biometric identity sensor in your Designated Device to perform the authentication using the information on that sensor. GPS will not collect and store your biometric credentials in any event.",
            "當您使用「生物識別」功能登入應用程式及兌換獎勵時，您是利用儲存在您指定裝置上的指紋或面部地圖（視情況而定）來認證您的身份，而非賬戶密碼。應用程式將使用您指定裝置中的生物識別感應器，使用該感應器上的資訊進行認證。GPS在任何情況下均不會收集和儲存您的生物識別資料。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Please ensure to store only your own biometric credentials on your Designated Device and use your own biometric credentials to login to the App. Notwithstanding this clause, if you store any other person’s biometric credentials on your Designated Device, you will be taken to have permitted that other person to use his/her biometric credentials to access your GPS user account, including but not limited to making purchase and bookings.",
            "請確保僅在您的指定裝置上儲存您自己的生物識別資料，並使用您的生物識別資料登入應用程式。儘管有此條款，如果您在指定裝置上儲存了其他人的生物識別資料，則視為您允許該人使用他/她的生物識別資料來使用您的GPS用戶帳戶，包括但不限於進行購買和預訂。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "You should not use facial maps for authentication if you have an identical twin sibling or if your facial features are developing or are expected to change.",
            "如果您有一個雙胞胎兄弟姐妹，或者您的面部特徵正在發育或預期會有變化，則不應使用面部地圖進行認證。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            'The "Biometric Authentication" function and the biometric identity sensor are integrated into the permitted mobile device of the users and are provided and operated by third-party service providers. GPS does not operate nor control the “Biometric Authentication” function and biometric identity sensor. GPS is not responsible for any issues arising from, including but not limited to the related technology and their performance. Please contact your mobile phone or mobile device service provider if you have any queries, opinions, and complaints.',
            "「生物識別」功能及生物識別感應器整合於用戶允許使用的移動裝置中，由第三方服務提供商提供並運營。GPS既不運營也不控制「生物識別」功能及生物識別感應器。對於包括但不限於相關技術及其性能所引起的任何問題，GPS均不承擔責任。如果您有任何疑問、意見或投訴，請聯繫您的手機或移動裝置服務提供商。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS reserves the right to modify or suspend the use of the “Biometric Authentication” function in mobile phone or mobile device to login to the App, and the right to modify these terms and conditions from time to time without prior notice. In case of disputes, GPS’s decision is final.",
            "GPS保留隨時修改、暫停使用手機或移動裝置的「生物識別」功能登入應用程式的權利，以及隨時修改這些條款及條件的權利，恕不另行通知。如有爭議，GPS的決定為最終決定。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

const Section7 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString("Our Liability", "責任")}

      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "To the extent permitted by law, GPS and the Third-Party Vendor(s) exclude all liability (whether arising in contract, in negligence indirect, special, incidental, consequential, exemplary, or otherwise) for death, injuries, losses or damages which a user may incur that occur with the GPS premises.",
            "在法律允許的範圍內，GPS及第三方供應商排除一切因合約、疏忽、間接、特殊、附帶、後果性、懲罰性或其他原因而引起的死亡、傷害、損失或損害的責任，該等事故發生在GPS場所內。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS do not assume any liability for the quantity, quality, fitness for purpose, condition and care and skill, or do not make any representations of, any goods and/or services provided by the Third-Party Vendors. GPS do not warrant or guarantee the accuracy or completeness of the information (including photos and images, and services of the product) displayed on the Third-Party Vendors’ listing/offering on the App.",
            "GPS不承擔任何第三方供應商所提供的商品和/或服務的數量、質量、適銷性、狀況、護理和技能的責任，也不對第三方供應商在應用程式上展示的信息（包括照片和圖像以及產品服務）的準確性或完整性作出任何保證或承諾。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS shall not be responsible for any obligations and liabilities in relation to the products or services provided by the respective product or service provider(s).",
            "對於相關產品或服務提供商所提供的產品或服務的任何義務和責任，GPS不承擔責任。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS will not be held responsible for any loss or damage due to third-party access to The App from i) phishing activities; ii) stolen or loss of personal mobile devices / login credentials; and/or iii) failure to inform GPS after a change of the registered mobile number.",
            "GPS不會對任何因第三方使用應用程式而導致的損失或損害承擔責任，包括但不限於：釣魚活動；手機或個人移動設備的失竊或遺失；以及/或未在更改註冊手機號碼後通知GPS。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS and the partnering Third-Part Vendors will not bear any responsibility in any case of network problems, system malfunctioning, poor phone reception or blocking by third-party applications, incomplete/ inaccurate/ missing information provided by users that cause the delay, loss, mistake or corruption of an information transfer, which caused failure of completion of order/transaction, failure of delivery of confirmation email for pick-up verification or other issues of order/transaction.",
            "對於任何因網絡問題、系統故障、手機接收不良或被第三方應用程式封鎖，以及用戶提供的不完整/不準確/遺漏的信息導致的信息傳輸延遲、丟失、錯誤或損壞，從而導致訂單/交易未能完成、確認電子郵件的逾期送達或其他訂單/交易問題，GPS及合作的第三方供應商均不承擔責任。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "All purchases and transactions are also subject to the terms and conditions of the Third-Party Vendor(s).",
            "所有購買和交易亦受第三方供應商的條款和條件的約束。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "All photos and descriptions on this App are for reference only.",
            "本應用程式上的所有照片和描述僅供參考。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "As some states or authorities do not allow the exclusion or limitation of liability for consequential or incidental damages, the liability of GPS and partnering Third-Party Vendors, their agents, representatives, and service providers will be limited to the extent permitted by law in such states or authorities.",
            "由於某些國家或司法管轄區不允許排除或限制後果性或附帶損害的責任，因此GPS及其合作的第三方供應商、其代理人、代表和服務提供商的責任將在這些國家或司法管轄區法律允許的範圍內受到限制。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Users agree to indemnify and hold harmless us, GPS, the App, Inno-Sports, and the Third-Party Vendors, its directors, officers, employees, representatives, agents, and affiliates, from any and all third-party claims, liability, damages and/or costs (including but not limited to, legal fees) arising from the users' use of the App and facilities located within GPS or breach of these Terms.",
            "用戶同意賠償並保障我們，GPS，應用程式，創新運動，以及第三方供應商，其董事、管理層、員工、代表、代理人和關聯公司，不受任何第三方的索賠、責任、損害和/或成本（包括但不限於法律費用）所影響，這可能源於用戶使用該應用程式和GPS內的設施或違反這些條款。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "If any provision of these Terms of Use is found to be invalid by any court having competent authority, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force and effect. No waiver of any provision in these Terms of Use shall be deemed a further or continuing waiver of such provision or any other provision. GPS shall have the right of final decision in any dispute in relation to these Terms.",
            "如果這些使用條款中的任何條款被有管轄權的法院認定為無效，該無效條款不應影響這些使用條款其餘條款的有效性，其餘條款應繼續完全有效。在這些使用條款中，對於任何條款的放棄不應被視為進一步或持續放棄該條款或任何其他條款。GPS在與這些條款相關的任何爭議中擁有最終決定權。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

const Section8 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString("General", "一般條款")}

      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "GPS shall have the right to modify these terms and conditions, pricing, and offerings at any time without prior notice.",
            "GPS保留隨時修改這些條款及細則、價格和優惠的權利，恕不事先通知。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Except for manifest errors, the records of The App regarding the user are conclusive.",
            "除明顯錯誤外，應用程式關於用戶的記錄為決定性的。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Users are responsible to keep themselves updated with respect to the structure and content of GPS and the App, including these terms and conditions. Registering as a user will be deemed as an acceptance of all relevant changes. GPS will not be liable for any losses or damage resulting from changes to the structure and content, including these terms and conditions.",
            "用戶有責任使自己瞭解GPS和應用程式的架構和內容的更新，包括這些條款及細則。註冊為用戶將被視為接受所有相關變更。對於因變更結構和內容，包括這些條款及細則而造成的任何損失或損害，GPS不承擔責任。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "While making reasonable efforts to ensure the provision of the App is updated, GPS does not warrant or represent that the App shall be provided in a secure, timely, uninterrupted, error-free, free of technical difficulties or defects or viruses. Users acknowledged and agreed that use of or reliance upon the App and any content, goods, products, or services accessed or obtained thereby is at their own sole risk and discretion.",
            "GPS盡力確保應用程式的提供是最新的，但不保證應用程式將安全、及時、不間斷、無錯誤、無技術困難或缺陷或無病毒地提供。用戶承認並同意，使用或依賴應用程式及其內容、商品、產品或服務是用戶自己的風險和選擇。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Users should expect temporary interruptions and suspension of the App due to scheduled or regular/ ad hoc system maintenance work, downtimes attributable to internet or electronic communications or events of force majeure. GPS will not bear any responsibilities as a result thereof.",
            "用戶應預期應用程式會因計劃性或不定期/臨時系統維護工作、互聯網或電子通訊的中斷或不可抗力事件而暫時中斷和暫停。對於因此而產生的任何責任，GPS不承擔責任。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Users hereby express their agreement and consent to GPS and Third-Party Vendors and any of its affiliate companies collecting, using, processing, transfer and disclosing the Personal Data in accordance with these Terms and their respective Privacy Policies. The Privacy Policy is available via the links on the App and shall form a part of these Terms. GPS and its partners handling any Personal Data collected through the App or facilities must comply with applicable privacy laws, including the Personal Data (Privacy) Ordinance of Hong Kong. GPS expects all operators and working partners to respect the privacy of users by protecting Personal Data in accordance with legal requirements.",
            "用戶在此表示同意並授權GPS及第三方供應商及其任何關聯公司收集、使用、處理、轉讓及披露個人資料，按照這些條款及各自的隱私政策。點數的隱私政策可以通過應用程式上的鏈接獲得，並應成為這些條款的一部分。用戶在此同意GPS以及第三方合作伙伴及其關係企業依據本條款及各自隱私政策收集、使用、處理、轉讓和披露個人資料。隱私政策的連結可在手機應用程式找到，構成本條款的一部分。GPS及其合作夥伴處理任何透過手機應用程式或設施收集的個人資料，必須遵守適用的隱私法律，包括香港個人資料(私隱)條例。GPS期望所有營運人員和合作夥伴通過保護個人資料，尊重使用者的隱私權。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS has the right to suspend any users’ access to the facilities and the App for any reason, including but not limited to unacceptable use or breach of these Terms and Conditions. GPS may suspend a user's access for up to 3 months for a violation. The final decision on lifting any suspension or reinstating a suspended user is at GPS' sole discretion.",
            "GPS有權因任何理由暫停使用者進入設施和使用手機應用程式的權限，包括但不限於不當使用或違反本條款。GPS可因違規暫停3個月。最終決定是否解除暫停或恢復被暫停使用者資格由GPS全權決定。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS and the Third-Party Vendors reserve the right to cancel any purchase and booking, and/or suspend or deactivate any users’ access to the facilities and use of the App in its sole discretion if it reasonably suspects or detects fraudulent behavior or activity associated with the order.",
            "GPS和第三方供應商保留取消任何購買和預訂的權利，並/或在合理懷疑或檢測到與訂單相關的欺詐行為或活動時，自行決定暫停或停用任何用戶進入設施和使用應用程式的權利。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS and Third-Party Vendors may, but shall be under no obligation to, provide a warning prior to termination of any users.",
            "GPS和第三方供應商可能但沒有義務在暫停任何用戶之前提供警告。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS may amend these Terms at any time and from time to time in its sole discretion. The amended Terms shall be effective immediately upon posting and users agree to the new Terms by continued access to the facility and use of the App.",
            "GPS可能會隨時自行決定修改這些條款。修訂後的條款一經公布即時生效，用戶通過繼續使用設施和使用應用程式即表示同意新條款。"
          )}
        </Styled.ListItem>

        <Styled.ListItem tmp-order="8.11">
          {getLocalisedString(
            "For any questions or complaints about GPS, users can contact us by sending an email to enquiry@goparksports.hk. The email content must include the User’s ID, Username, registered mobile number, email address, and contact number.",
            "如對GPS有任何疑問或投訴，用戶可以通過發送電子郵件至enquiry@goparksports.hk聯繫我們。電子郵件內容必須包括用戶的ID、用戶名、註冊手機號碼、電子郵件地址和聯絡電話。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "GPS retains the absolute right to modify or amend the terms and conditions at any time without prior notice. In the event of a dispute, GPS reserves the final decision-making authority on the interpretation of the terms and conditions.",
            "GPS保留隨時修改或修訂條款及條件的絕對權利，恕不另行通知。如有爭議，GPS保留對條款和條件的解釋的最終決定權。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

const Section9 = () => {
  return (
    <Styled.TitleListItem>
      {getLocalisedString(
        "Unauthorized Commercial Activities",
        "未經授權的商業活動"
      )}

      <Styled.OrderList>
        <Styled.ListItem>
          {getLocalisedString(
            "Unauthorized Commercial Activities: Users of GO PARK Sports facilities are prohibited from engaging in any unauthorized commercial activities, including but not limited to, hosting events for profit, conducting classes, or offering services for a fee without prior written consent from GO PARK Sports management.",
            "未經授權的商業活動：使用 GO PARK Sports 設施的用戶禁止從事任何未經授權的商業活動，包括但不限於，舉辦盈利活動、開展課程或提供有償服務，必須事先獲得 GO PARK Sports 管理層的書面同意。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Commercial Event Photography: The use of GO PARK Sports facilities for commercial event photography or videography is strictly prohibited unless prior approval is obtained from management.",
            "商業活動攝影：除非事先獲得管理層的批准，否則嚴禁在 GO PARK Sports 設施內進行商業活動攝影或錄影。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Enforcement of Policies: GO PARK Sports reserves the right to monitor activities within the facility. Any individual or group found engaging in unauthorized commercial activities will be subject to immediate action.",
            "政策執行：GO PARK Sports 保留監控設施內活動的權利。任何被發現從事未經授權商業活動的個人或團體將受到立即處理。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Refusal of Access: Management may refuse access to any individual or group suspected of violating these terms. This includes, but is not limited to, removal from the premises.",
            "拒絕進入：管理層可以拒絕任何被懷疑違反這些條款的個人或團體的進入權，包括但不限於將其驅逐出場。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Legal Action: GO PARK Sports retains the right to pursue legal action against individuals or groups that violate these terms, including seeking damages for any losses incurred as a result of unauthorized commercial activities.",
            "法律行動：GO PARK Sports 保留對違反這些條款的個人或團體提起法律行動的權利，包括因未經授權的商業活動造成的損失索賠。"
          )}
        </Styled.ListItem>

        <Styled.ListItem>
          {getLocalisedString(
            "Amendments: GO PARK Sports reserves the right to amend these terms and conditions at any time. Users will be notified of any significant changes.",
            "修訂：GO PARK Sports 保留隨時修訂這些條款和條件的權利。用戶將被通知任何重大變更。"
          )}
        </Styled.ListItem>
      </Styled.OrderList>
    </Styled.TitleListItem>
  );
};

export default {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
  Section9,
};
