import { SaiShaAPIResponse, SaiShaPaymentResultBase } from "../@types/common";
import {
  appearGlobalError,
  disappearGlobalLoading,
} from "common/context/requests/globalRequest";
import { getLocalisedString } from "common/utils/stringHelper";
import i18n from "i18n";
import { DispatchWithoutAction } from "react";
import { ROUTE_BASE } from "Route";

// TODO: move response body to some definition file in common
type EventBookingSuccessResponseBody = SaiShaPaymentResultBase & {
  booking: {
    status: string;
  };
} & Record<string, unknown>;

type FacilityBookingSuccessResponseBody = SaiShaPaymentResultBase & {
  order: { status: string }[];
} & Record<string, unknown>;

type CoachBookingSuccessResponseBody = SaiShaPaymentResultBase & {
  UpdatedBooking: {
    status: string;
  }[];
} & Record<string, unknown>;

type ProgrammeBookingSuccessResponseBody = SaiShaPaymentResultBase & {
  enrollment: {
    status: string;
  };
} & Record<string, unknown>;

type PaymentTypeToResponseBody = {
  Event: EventBookingSuccessResponseBody;
  Facility: FacilityBookingSuccessResponseBody;
  Coach: CoachBookingSuccessResponseBody;
  Programme: ProgrammeBookingSuccessResponseBody;
  Package: SaiShaPaymentResultBase;
  SportPass: SaiShaPaymentResultBase;
};

function checkIsFreeOrder<T extends keyof PaymentTypeToResponseBody>(
  orderResponse: SaiShaAPIResponse<{ result: PaymentTypeToResponseBody[T] }>,
  type: T
) {
  console.log("checkIsFreeOrder for type:", type);
  switch (type) {
    case "Event":
      return (
        (orderResponse?.result as EventBookingSuccessResponseBody)?.booking
          ?.status === "CONFIRM"
      );
    case "Facility":
      return (
        (orderResponse?.result as FacilityBookingSuccessResponseBody)?.order[0]
          ?.status === "CONFIRMED"
      );
    case "Coach":
      return (
        orderResponse?.result as CoachBookingSuccessResponseBody
      )?.UpdatedBooking?.every((booking) => booking.status === "CONFIRMED");
    case "Programme":
      return (
        (orderResponse?.result as ProgrammeBookingSuccessResponseBody)
          ?.enrollment?.status === "CONFIRM"
      );
    default:
      // sport pass and package
      return (
        orderResponse?.result?.CreatePaymentResult?.confirmedTransaction
          ?.overall_status == "CONFIRMED"
      );
  }
}

export async function createPayment<T extends keyof PaymentTypeToResponseBody>(
  res: SaiShaAPIResponse<{ result: PaymentTypeToResponseBody[T] }>,
  dispatch: DispatchWithoutAction,
  navigate: any,
  type: T
) {
  if (res?.success === true) {
    //create a hidden form element
    if (checkIsFreeOrder(res, type)) {
      console.log("free order");
      disappearGlobalLoading(dispatch);
      navigate(`/${i18n.language}/${ROUTE_BASE.PAYMENT}/freeorder/success`, {
        state: {
          isFreeOrder: true,
          transType:
            res?.result?.CreatePaymentResult?.confirmedTransaction?.typeV2 ??
            "ET", //default event for free event dont return type
        },
      });
    } else if (res?.result?.CreatePaymentResult?.mPayGatewayUrl != null) {
      // go to payment gateway
      const paymentResult = res?.result;

      const {
        CreatePaymentResult: { result, mPayGatewayUrl },
      } = paymentResult || {};

      const form = document.createElement("form");
      form.method = "POST";
      form.action = mPayGatewayUrl as string;

      //create form fields for each data item
      for (const key in result) {
        const value = result[key];
        const input = document.createElement("input") as HTMLInputElement;
        input.setAttribute("type", "hidden");
        input.setAttribute("name", key);
        input.setAttribute("value", value);
        form.appendChild(input);
      }

      //append the form to the document and submit it
      document.body.appendChild(form);
      form.submit();
    }
  } else {
    appearGlobalError(
      dispatch,
      getLocalisedString(
        res?.error?.localizedMessage?.en,
        res?.error?.localizedMessage?.zh
      )
    );
  }
}

type ConfirmBookingViewmodelReturn = SaiShaAPIResponse<{
  success: boolean;
  needVerification: boolean;
  isRedirectHandle: boolean;
  error: string;
}>;
export const createPaymentHandler =
  (res: ConfirmBookingViewmodelReturn) =>
  <T1, T2, T3, T4>({
    onSuccess,
    onNeedVerification,
    onRedirectHandle,
    onDefaultFailed,
  }: {
    onSuccess: () => Promise<T1>;
    onNeedVerification?: () => T2;
    onRedirectHandle: () => T3;
    onDefaultFailed: () => T4;
  }): Promise<T1> | T2 | T3 | T4 | undefined => {
    if (res.success === true) {
      return onSuccess();
    } else if (res?.needVerification) {
      return onNeedVerification ? onNeedVerification() : undefined;
    } else if (res?.isRedirectHandle) {
      return onRedirectHandle();
    } else {
      return onDefaultFailed();
    }
  };
