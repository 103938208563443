import { useEffect, useMemo, useState } from "react";
import { ProgramTypes } from "./MyBookingListViewModel";
import {
  cancelFacilityBooking,
  getEventBookingDetail as getEventBookingDetailApi,
  getMyFacilityBookingDetail,
  getEnrollmentDetail as getEnrollmentDetailApi,
  inviteEventGuest,
  removeEventGuest,
  updateFacilityBookingGuests,
  editEnrolment as editEnrolmentApi,
} from "../../network/booking";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalLoading,
} from "../../context/requests/globalRequest";
import {
  getLocalisedString,
  validateEmailFormat,
} from "../../utils/stringHelper";
import i18n from "../../../i18n";
import _ from "lodash";
import {
  cancelCoachBooking,
  getCoachBookingDetails,
  updateCoachLessonGuestlist,
} from "../../network/coachLesson";
import { requestSickLeave } from "../../network/monthlyProgram";
import { isEmptyValues } from "../../utils/common";
import useBookingParticipantInfo from "../Booking/BookingParticipantInfo";
import { useCommonFetcher } from "../Common/CommonViewModel";

export const ProgrammeLessonAttendanceStatus = {
  Pending: "Pending",
  Present: "Present",
  SickLeavePending: "SickLeavePending",
  SickLeave: "SickLeave",
  Absent: "Absent",
};

export const useMyBookingDetailViewModel = ({
  currentUser,
  bookingId,
  bookingType,
  dispatch,
}) => {
  const [bookingRecord, setBookingRecord] = useState(null);
  const [keyMemberList, setKeyMemberList] = useState([]);
  const [guestList, setGuestList] = useState(new Map());

  const { participantInfo, setParticipantInfo, isParticipantInfoValid, participantInfoErrors } =
    useBookingParticipantInfo(bookingType);
  const { fetcher } = useCommonFetcher();

  const isPastBooking = useMemo(() => {
    // Monthly Program Booking will be controlled by lesson status instead
    if (bookingType === ProgramTypes.Program) return false;

    // Applicable for Facility, Event & Coach Booking
    return bookingRecord?.isPastBooking ?? false;
  }, [bookingRecord, bookingType]);

  const isAllowEditGuest = useMemo(() => {
    // Monthly Program Booking does not involve guest invitation logic
    if (bookingType === ProgramTypes.Program) return false;

    // Applicable for Facility, Event & Coach Booking
    return bookingRecord?.isAllowEditGuest ?? false;
  }, [bookingRecord, bookingType]);

  const isAllowCancel = useMemo(() => {
    // Monthly Program & Event Booking does not involve user cancel booking logic
    if (
      bookingType === ProgramTypes.Program ||
      bookingType === ProgramTypes.Event
    )
      return false;

    // Applicable for Facility & Coach Booking
    return bookingRecord?.isAllowCancel ?? false;
  }, [bookingRecord, bookingType]);

  const paymentMethod = useMemo(() => {
    console.log("paymentType", bookingRecord?.transactions);
    const uniqueTransactionList = _.uniqBy(
      bookingRecord?.transactions,
      (transaction) => transaction?.paymethod
    );
    const payMethodList = _.map(uniqueTransactionList, (transaction) => {
      return transaction.paymethod;
    });
    return _.join(payMethodList, "\n");
  }, [bookingRecord]);

  useEffect(() => {
    getBookingDetail();
  }, [bookingId, bookingType]);

  const getBookingDetail = async () => {
    try {
      appearGlobalLoading(dispatch);
      switch (bookingType) {
        case ProgramTypes.Program:
          await getEnrollmentDetail(bookingId);
          break;
        case ProgramTypes.Coach:
          await getCoachBookingDetail(bookingId);
          break;
        case ProgramTypes.Event:
          await getEventBookingDetail(bookingId);
          break;
        case ProgramTypes.Facility:
          await getFacilityBookingDetail(bookingId);
          break;
      }
      disappearGlobalLoading(dispatch);
    } catch (err) {
      disappearGlobalLoading(dispatch);
      appearGlobalError(
        dispatch,
        getLocalisedString(
          err?.response?.data?.error?.localizedMessage?.en,
          err?.response?.data?.error?.localizedMessage?.tc
        )
      );
    }
  };

  const getEnrollmentDetail = async (bookingId) => {
    const res = await getEnrollmentDetailApi({ bookingId });
    console.log(res.result);
    setBookingRecord({ ...res.result, lessons: res.result.lessons });
    setParticipantInfo({
      participantName: res.result?.participantName,
      age: res.result?.age,
    });
  };

  const getCoachBookingDetail = async (orderId) => {
    const res = await getCoachBookingDetails({ orderId });
    setBookingRecord(res);
  };

  const getEventBookingDetail = async (bookingId) => {
    const res = await getEventBookingDetailApi({ bookingId });
    console.log(res.result);
    setBookingRecord(res?.result);
  };

  const getFacilityBookingDetail = async (orderId) => {
    const res = await getMyFacilityBookingDetail({ orderId });
    setBookingRecord(res);
  };

  const checkIsBookingOwner = () => {
    switch (bookingType) {
      case ProgramTypes.Coach:
        return currentUser?._id === bookingRecord?.user_id?._id;
      case ProgramTypes.Program:
        return currentUser?._id === bookingRecord?.userId;
      default:
        return currentUser?._id === bookingRecord?.user_id;
    }
  };

  const checkIsBookingOwnerEmail = (email) => {
    return (
      String(email).toLowerCase() === String(currentUser?.email).toLowerCase()
    );
  };

  const guestLimit = useMemo(() => {
    if (!bookingRecord) return 0;
    if (bookingType === ProgramTypes.Event) {
      return bookingRecord.attendees_count - 1;
    }

    if (bookingType === ProgramTypes.Facility) {
      const facilityBooking = bookingRecord.bookings[0];
      // Head Count base use attendees
      if (!isEmptyValues(facilityBooking?.attendees))
        return Math.max(facilityBooking?.attendees - 1, 0);
      const isRequireKeyMember = facilityBooking?.facility?.requireKeyMembers();
      const guestPerBooking = facilityBooking?.facility?.guest_per_booking || 0;
      // Others use guest per booking
      // Shd ignore 3 keymembers for isRequireKeyMember facilities
      if (isRequireKeyMember) {
        return Math.max(guestPerBooking - 3, 0);
      }
      return guestPerBooking;
    }

    if (bookingType === ProgramTypes.Coach) {
      const coachBooking = bookingRecord;
      if (coachBooking.lessonType === "1to1") return 0;
      if (coachBooking.lessonType === "1to2") return 1;
      if (coachBooking.lessonType === "1to3") return 2;
    }
    return 0;
  }, [bookingRecord]);

  const addGuests = async (csvInput) => {
    try {
      appearGlobalLoading(dispatch);
      let guests = csvInput?.split(",");
      let result = {};
      if (guests?.some((email) => !validateEmailFormat(email))) {
        throw new Error(i18n.getFixedT()("myBooking:detail.invalidEmailError"));
      }
      switch (bookingType) {
        case ProgramTypes.Program:
          break;
        case ProgramTypes.Coach:
          result = await addCoachBookingGuests(guests);
          break;
        case ProgramTypes.Event:
          result = await addEventBookingGuests(guests);
          break;
        case ProgramTypes.Facility:
          result = await addFacilityBookingGuests(guests);
          break;
      }
      await getBookingDetail();
      disappearGlobalLoading(dispatch);
      return result.data;
    } catch (err) {
      disappearGlobalLoading(dispatch);
      // refresh booking info
      getBookingDetail();
      throw err;
    }
  };

  const addCoachBookingGuests = async (guests) => {
    try {
      /// For upserting coach booking guest list
      let tmpGuestList = _.cloneDeep(getBookingGuestList())?.map((guest) => {
        return { email: guest.email };
      });
      guests?.forEach((guestEmail) => {
        if (checkIsBookingOwnerEmail(guestEmail)) {
          throw new Error(
            i18n.getFixedT()("myBooking:detail.inviteBookingOwnerError", {
              mail: guestEmail,
            })
          );
        }
        let index = tmpGuestList?.findIndex(
          (guest) =>
            String(guest.email).toLowerCase() ===
            String(guestEmail).toLowerCase()
        );
        if (index > -1) {
          // Existing
          throw new Error(
            i18n.getFixedT()("myBooking:detail.duplicatedEmailError", {
              mail: guestEmail,
            })
          );
        } else {
          // Non existing
          tmpGuestList?.push({
            email: guestEmail,
          });
        }
      });

      if (tmpGuestList.length > guestLimit) {
        console.log(tmpGuestList.length, guestLimit);
        throw new Error(
          i18n.getFixedT()("myBooking:detail.guestLimitExceededError", {
            guestLimit: guestLimit,
          })
        );
      }
      console.log(tmpGuestList);
      // TODO: Check guest limit for facility
      return await updateCoachLessonGuestlist({
        orderId: bookingRecord?._id,
        guestlist: tmpGuestList,
      });
    } catch (err) {
      throw err;
    }
  };

  /// guests: array of email
  const addEventBookingGuests = async (guests) => {
    try {
      //  For duplicate checking only
      let tmpGuestList = _.cloneDeep(getBookingGuestList());
      guests?.forEach((guestEmail) => {
        if (checkIsBookingOwnerEmail(guestEmail)) {
          throw new Error(
            i18n.getFixedT()("myBooking:detail.inviteBookingOwnerError", {
              mail: guestEmail,
            })
          );
        }
        let index = tmpGuestList?.findIndex(
          (guest) =>
            String(guest.email).toLowerCase() ===
            String(guestEmail).toLowerCase()
        );
        if (index > -1) {
          // Existing
          throw new Error(
            i18n.getFixedT()("myBooking:detail.duplicatedEmailError", {
              mail: guestEmail,
            })
          );
        } else {
          // Non existing
          tmpGuestList?.push({ email: guestEmail });
        }
      });
      console.log(tmpGuestList);

      if (tmpGuestList.length > guestLimit) {
        throw new Error(
          i18n.getFixedT()("myBooking:detail.guestLimitExceededError", {
            guestLimit: guestLimit,
          })
        );
      }
      return await inviteEventGuest({
        orderId: bookingId,
        guestlist: guests,
      });
    } catch (err) {
      throw err;
    }
  };

  /// guests: array of email
  const addFacilityBookingGuests = async (guests) => {
    try {
      /// For upserting facility booking guest list
      let tmpGuestList = _.cloneDeep(getBookingGuestList())?.map((guest) => {
        return { email: guest.email, key_member: guest.key_member };
      });
      guests?.forEach((guestEmail) => {
        if (checkIsBookingOwnerEmail(guestEmail)) {
          throw new Error(
            i18n.getFixedT()("myBooking:detail.inviteBookingOwnerError", {
              mail: guestEmail,
            })
          );
        }
        let index = tmpGuestList?.findIndex(
          (guest) =>
            String(guest.email).toLowerCase() ===
            String(guestEmail).toLowerCase()
        );
        if (index > -1) {
          // Existing
          let existingGuest = tmpGuestList[index];
          if (existingGuest?.key_member == true) {
            //  Check duplicated with key members
            throw new Error(
              i18n.getFixedT()(
                "myBooking:detail.duplicatedWithKeyMemberError",
                { mail: guestEmail }
              )
            );
          } else {
            throw new Error(
              i18n.getFixedT()("myBooking:detail.duplicatedEmailError", {
                mail: guestEmail,
              })
            );
          }
        } else {
          // Non existing
          tmpGuestList?.push({
            email: guestEmail,
            key_member: false,
          });
        }
      });
      console.log(tmpGuestList);
      const nonKeyMemberGuest = tmpGuestList.filter(
        (guest) => !guest.key_member
      );
      console.log(nonKeyMemberGuest.length, guestLimit);

      if (nonKeyMemberGuest.length > guestLimit) {
        throw new Error(
          i18n.getFixedT()("myBooking:detail.guestLimitExceededError", {
            guestLimit: guestLimit,
          })
        );
      }
      // TODO: Check guest limit for facility
      return await updateFacilityBookingGuests({
        orderId: bookingId,
        guestlist: tmpGuestList,
      });
    } catch (err) {
      throw err;
    }
  };

  const removeGuest = async (guestToRemove) => {
    console.log("Remove Guest: ", guestToRemove);
    try {
      appearGlobalLoading(dispatch);
      switch (bookingType) {
        case ProgramTypes.Coach:
          await removeCoachBookingGuest(guestToRemove?.email);
          break;
        case ProgramTypes.Event:
          await removeEventBookingGuest(guestToRemove?.email);
          break;
        case ProgramTypes.Facility:
          await removeFacilityBookingGuest(guestToRemove?.email);
          break;
      }
      await getBookingDetail();
      disappearGlobalLoading(dispatch);
    } catch (err) {
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, err?.message);
      // refresh booking info
      getBookingDetail();
    }
  };

  const removeCoachBookingGuest = async (guestEmail) => {
    try {
      let tmpGuestList = getBookingGuestList()?.map((guest) => {
        return { email: guest.email };
      });
      let index = tmpGuestList?.findIndex(
        (guest) => guest.email === guestEmail
      );
      // Remove guest
      tmpGuestList.splice(index, 1);
      tmpGuestList.forEach(function (item) {
        delete item._id;
      });
      console.log("Remove Guest: ", tmpGuestList, index);
      await updateCoachLessonGuestlist({
        orderId: bookingRecord?._id,
        guestlist: tmpGuestList,
      });
    } catch (err) {
      throw err;
    }
  };

  const removeEventBookingGuest = async (guestEmail) => {
    try {
      let tmpGuestList = getBookingGuestList();
      tmpGuestList.forEach(function (item) {
        delete item.user_id;
      });
      if (tmpGuestList?.some((guest) => guest.email === guestEmail)) {
        await removeEventGuest({
          orderId: bookingId,
          email: guestEmail,
        });
      }
      // Remove guest
    } catch (err) {
      throw err;
    }
  };

  const removeFacilityBookingGuest = async (guestEmail) => {
    try {
      let tmpGuestList = getBookingGuestList()?.map((guest) => {
        return { email: guest.email, key_member: guest.key_member };
      });
      let index = tmpGuestList?.findIndex(
        (guest) => guest.email === guestEmail
      );
      // Remove guest
      tmpGuestList.splice(index, 1);
      tmpGuestList.forEach(function (item) {
        delete item._id;
      });
      console.log("Remove Guest: ", tmpGuestList, index);
      await updateFacilityBookingGuests({
        orderId: bookingId,
        guestlist: tmpGuestList,
      });
    } catch (err) {
      throw err;
    }
  };

  const getBookingGuestList = () => {
    switch (bookingType) {
      case ProgramTypes.Facility:
        return bookingRecord?.bookings?.find(() => true)?.guestlist ?? [];
      case ProgramTypes.Coach:
      case ProgramTypes.Event:
        return bookingRecord?.guestlist ?? [];
      default:
        return [];
    }
  };

  const editBooking = async () => {
    let res;
    switch (bookingType) {
      case ProgramTypes.Program:
        res = await fetcher({
          api: () =>
            editEnrolmentApi({
              enrolmentId: bookingId,
              payload: {
                participantName: participantInfo.participantName,
                age: participantInfo.age,
              },
            }),
        });
        if (!res?.success) {
          // refresh booking info
          getBookingDetail();
        }
        return res;
      default:
        break;
    }
  };

  const cancelBooking = async () => {
    try {
      appearGlobalLoading(dispatch);
      let res;
      switch (bookingType) {
        case ProgramTypes.Coach:
          res = await cancelCoachBooking({ orderId: bookingId });
          break;
        case ProgramTypes.Facility:
          res = await cancelFacilityBooking({ orderId: bookingId });
          break;
        default:
          break;
      }
      disappearGlobalLoading(dispatch);
      return res;
    } catch (err) {
      console.log(err);
      let errorMsg = getLocalisedString(
        err?.response?.data?.error?.localizedMessage?.en,
        err?.response?.data?.error?.localizedMessage?.zh
      );
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, errorMsg);
      // refresh booking info
      getBookingDetail();
    }
  };

  const requestProgramLessonSickLeave = async (lessonId, documentLink) => {
    try {
      appearGlobalLoading(dispatch);
      const res = await requestSickLeave({ lessonId, documentLink });
      if (res?.success) {
        await getBookingDetail();
      }
      return res;
    } catch (err) {
      disappearGlobalLoading(dispatch);
      console.log(err);
      let errorMsg = getLocalisedString(
        err?.response?.data?.error?.localizedMessage?.en,
        err?.response?.data?.error?.localizedMessage?.zh
      );
      appearGlobalError(dispatch, errorMsg);
      // refresh booking info
      getBookingDetail();
      if (err?.response?.data) {
        return err.response.data;
      }
      return {
        success: false,
        error: err?.message,
      };
    }
  };

  return {
    bookingRecord,
    paymentMethod,
    participantInfo,
    setParticipantInfo,
    isParticipantInfoValid,
    participantInfoErrors,  
    guestLimit,
    isPastBooking,
    isAllowEditGuest,
    isAllowCancel,
    checkIsBookingOwner,
    getBookingGuestList,
    addGuests,
    addEventBookingGuests,
    removeGuest,
    editBooking,
    cancelBooking,
    getBookingDetail,
    requestProgramLessonSickLeave,
  };
};
