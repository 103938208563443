import React, { useState } from "react";
import Styled from "../Styled";
import {
  ProgramBooking,
  ProgramBookingLesson,
  ProgramParticipantInfoReturnType,
} from "models/Programme";
import cn from "classnames";
import LessonAttendanceItem, {
  LessonAttendanceProps,
} from "components/MyBookingListingCard/MyProgramListingCard/LessonAttendanceItem";
import ProgramSickLeaveModal from "components/modals/ProgramSickLeaveModal/ProgramSickLeaveModal";
import { ProgrammeLessonAttendanceStatus } from "common/models/MonthlyProgram";
import { useTranslation } from "react-i18next";
import { ReactComponent as DocumentSVG } from "../../../assets/document.svg";
import { SaiShaAPIResponse } from "../../../@types/common";
import { BookingParticipantInfo } from "models";
import CommonTextfield from "components/common/Textfield";
import GeneralButton from "components/common/Button";
import theme from "utils/theme";
import ProgramOrderInfo from "pages/MonthlyProgram/Components/booking/ProgramOrderInfo";
import ParticipantInfo from "pages/MonthlyProgram/Components/booking/ParticipantInfo";

type ProgramParticipantInfoCardProps = {
  isEditInfoAllow?: boolean;
  onEditParticipantInfo: (
    payload: BookingParticipantInfo
  ) => Promise<SaiShaAPIResponse<object>>;
} & ProgramParticipantInfoReturnType;

const ProgramParticipantInfoCard = ({
  participantInfo,
  setParticipantInfo,
  isParticipantInfoValid,
  onEditParticipantInfo,
  isEditInfoAllow,
  participantInfoErrors,
}: ProgramParticipantInfoCardProps) => {
  const { t } = useTranslation("booking");
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = () => {
    onEditParticipantInfo(participantInfo).then((res) => {
      setIsEditing(res?.success ? false : true);
    });
  };

  return (
    <Styled.SharedCard
      className={cn(
        "w-full px-[22px] py-[22px]",
        "lg:px-[37px]",
        "lg:py-[22px]",
        "font-poppins"
      )}
    >
      <div className="flex justify-between  items-center leading-10 mb-4">
        <h2 className="font-medium">{t("bookingDetail.participantInfo")}</h2>

        {isEditInfoAllow ? (
          isEditing ? (
            <GeneralButton
              onClick={handleSave}
              className="w-10 h-10 "
              bgColor={theme.orange}
              disabled={!isParticipantInfoValid}
            >
              {t("common:button.save")}
            </GeneralButton>
          ) : (
            <GeneralButton
              onClick={() => {
                setIsEditing(true);
              }}
              className="w-10 h-10"
              disabled={!isEditInfoAllow}
            >
              {t("common:button.edit")}
            </GeneralButton>
          )
        ) : (
          <div></div>
        )}
      </div>
      <ParticipantInfo
        participantInfo={participantInfo}
        setParticipantInfo={setParticipantInfo}
        disabled={!isEditing || !isEditInfoAllow}
        errors={isEditing ? participantInfoErrors : []}
      />
    </Styled.SharedCard>
  );
};

export default ProgramParticipantInfoCard;
