import { FieldError } from "components/common/Form/styles";
import CommonTextfield from "components/common/Textfield";
import { IMonthlyProgrammeBookingViewModel } from "models/Programme";
import { useTranslation } from "react-i18next";

function ParticipantInfo({
  participantInfo,
  setParticipantInfo,
  disabled,
  errors,
}: {
  participantInfo: IMonthlyProgrammeBookingViewModel["participantInfo"];
  setParticipantInfo: IMonthlyProgrammeBookingViewModel["setParticipantInfo"];
  errors?: IMonthlyProgrammeBookingViewModel["participantInfoErrors"];
  disabled?: boolean;
}) {
  const { t } = useTranslation("booking");
  return (
    <div className="w-full max-w-[334px] flex flex-col gap-4 font-poppins">
      <h3 className="font-normal text-app-black">
        {t("bookingDetail.participantName")}
      </h3>
      <CommonTextfield
        value={participantInfo.participantName}
        name="participantName"
        sx={{
          "& input": {
            fontFamily: "Poppins",
          },
        }}
        onChange={(e) =>
          setParticipantInfo((prev) => ({
            ...prev,
            participantName: e.target.value,
          }))
        }
        disabled={disabled}
        placeholder={
          disabled ? "-" : t("bookingDetail.participantNamePlaceholder")
        }
      />
      {errors?.find((e) => e.context?.key === "participantName") && (
        <FieldError>
          {errors.find((e) => e.context?.key === "participantName")?.message}
        </FieldError>
      )}
      <h3>{t("bookingDetail.participantAge")}</h3>

      <CommonTextfield
        value={participantInfo.age}
        name="age"
        sx={{
          "& input": {
            fontFamily: "Poppins",
          },
        }}
        type="text"
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        onChange={(e) => {
          const value = e.target.value;
          // Only allow if first character is numeric
          if (value === "" || /^\d+$/.test(value)) {
            setParticipantInfo((prev) => ({
              ...prev,
              age: value === "" ? null : parseInt(value, 10),
            }));
          }
        }}
        disabled={disabled}
        placeholder={
          disabled ? "-" : t("bookingDetail.participantAgePlaceholder")
        }
      />
      {errors?.find((e) => e.context?.key === "age") && (
        <FieldError>
          {errors.find((e) => e.context?.key === "age")?.message}
        </FieldError>
      )}
    </div>
  );
}

export default ParticipantInfo;
