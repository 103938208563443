import { useState, useEffect, useMemo } from "react";
import i18n from "i18n";
import { formatDateToString, getDayOfWeekSlug } from "../../utils/DateHelper";
import {
  getLocalisedString,
  validateEmailFormat,
} from "../../utils/stringHelper";
import { VoucherType } from "../VoucherViewModel";
import { isEmptyValues } from "../../../utils/common";
import { usePromoCodeViewModel } from "../Payment/PromoCodeViewModel";
import { PromoCodeBookingType } from "../../models/PromoCode";
import dayjs from "../../utils/dayjs";

export const TimeslotType = {
  PEAK: "peak",
  NORMAL: "normal",
};

export const useFacilityBookingDetailViewModel = ({
  currentUser,
  facility,
  venue,
  selectedDate,
  selectedTimeslots,
  selectedPurpose,
  bookingIds,
  numOfAttendees,
  dispatch,
}) => {
  //   const [numOfAttendees, setNumberOfAttendees] = useState(1);

  const { checkPromoCodeAvailability, estimatePromoCodeDiscount } =
    usePromoCodeViewModel();

  const [keyMember1, setKeyMember1] = useState("");
  const [keyMember2, setKeyMember2] = useState("");
  const [keyMember3, setKeyMember3] = useState("");
  const [promoCode, setPromoCode] = useState(null);
  const [appliedVouchers, setVouchers] = useState([]);
  const [allowedVoucherTypes, setAllowedVoucherTypes] = useState([]);
  // const maxAttendeesPerBooking = (facility?.guest_per_booking ?? 0) + 1;

  const subtotalPrice = useMemo(() => {
    return (
      Array.from(selectedTimeslots?.values()).reduce(
        (sum, timeslot) => sum + timeslot.price,
        0
      ) * (numOfAttendees ?? 1)
    );
  }, [selectedTimeslots, numOfAttendees]);

  const subtotalPriceWithVoucher = useMemo(() => {
    return (
      subtotalPrice -
      appliedVouchers.reduce((acc, voucher) => acc + voucher?.voucher?.value, 0)
    );
  }, [subtotalPrice, appliedVouchers]);

  useEffect(() => {
    updateEstimatedPromoCodePrice();
  }, [subtotalPriceWithVoucher]);

  // Switch to check total price & allowed voucher types seperately
  // useEffect(() => {
  //   if (getTotalPrice() <= 0) {
  //     setAllowedVoucherTypes([]);
  //   }
  // }, [appliedVouchers, promoCode]);

  useEffect(() => {
    updateVoucherValue([]);
  }, []);

  const getBookingDetails = () => {
    return (
      Array.from(selectedTimeslots?.values()).map((timeslot) => {
        return {
          info: `${formatDateToString(
            dayjs.tz(selectedDate),
            getLocalisedString("YYYY-MM-DD (ddd)", "YYYY-MM-DD (dddd)"),
            getLocalisedString("en", "zh-hk")
          )}\n${timeslot.getDisplayTimeslot()} ${
            timeslot.isPeakHour
              ? `${i18n.getFixedT()("booking:bookingDetail.peak")}\nHK$${
                  timeslot.price
                }`
              : `${i18n.getFixedT()("booking:bookingDetail.normal")}\nHK$${
                  timeslot.price
                }`
          }`,
          price: timeslot.price,
        };
      }) ?? []
    );
  };

  // const onPressAddAttendees = () => {
  //   console.log('OnPressAdd');
  //   let newNumOfAttendees = numOfAttendees + 1;
  //   if (newNumOfAttendees <= maxAttendeesPerBooking) setNumberOfAttendees(newNumOfAttendees);
  // };

  // const onPressSubtractAttendees = () => {
  //   let newNumOfAttendees = numOfAttendees - 1;
  //   if (newNumOfAttendees >= 1) {
  //     setNumberOfAttendees(newNumOfAttendees);
  //   }
  // };

  const applyPromoCode = async (code) => {
    const res = await checkPromoCodeAvailability({
      code,
      bookingType: PromoCodeBookingType.FACILITY,
      orderAmount: subtotalPriceWithVoucher * 100,
    });

    if (res?.success && !isEmptyValues(res?.promoCode)) {
      setPromoCode(res?.promoCode);
    } else {
      setPromoCode(null);
    }
  };

  const updateEstimatedPromoCodePrice = async () => {
    if (!isEmptyValues(promoCode)) {
      const res = await estimatePromoCodeDiscount({
        promoCode: promoCode,
        orderAmount: subtotalPriceWithVoucher * 100,
      });

      if (res?.success && !isEmptyValues(res?.promoCode)) {
        setPromoCode(res?.promoCode);
      } else {
        setPromoCode(null);
      }
    }
  };

  const removePromoCode = () => {
    setPromoCode(null);
  };

  const updateVoucherValue = (originalVouchers) => {
    let remainingNormalHr = 0,
      remainingPeakHr = 0;
    let normalPrice = 0,
      peakPrice = 0;
    Array.from(selectedTimeslots?.values()).map((timeslot) => {
      if (timeslot.isPeakHour) {
        if (!peakPrice) peakPrice = timeslot.price;
        remainingPeakHr += numOfAttendees ?? 1;
      } else {
        if (!normalPrice) normalPrice = timeslot.price;
        remainingNormalHr += numOfAttendees ?? 1;
      }
    });

    let vouchers = originalVouchers.map((voucher) => {
      if (voucher?.voucher?.type == VoucherType.CASH) return voucher;
      if (voucher?.voucher?.peakHour && remainingPeakHr) {
        remainingPeakHr--;
        return {
          ...voucher,
          voucher: {
            ...voucher?.voucher,
            value: peakPrice,
          },
          appliedTo: TimeslotType.PEAK,
        };
      }
      if (voucher?.voucher?.normalHour && remainingNormalHr) {
        remainingNormalHr--;
        return {
          ...voucher,
          voucher: {
            ...voucher?.voucher,
            value: normalPrice,
          },
          appliedTo: TimeslotType.NORMAL,
        };
      }
      return null;
    });
    let allowedVouchers = [];
    if (remainingNormalHr) allowedVouchers.push(TimeslotType.NORMAL);
    if (remainingPeakHr) allowedVouchers.push(TimeslotType.PEAK);

    setAllowedVoucherTypes(allowedVouchers);
    setVouchers(vouchers);
  };

  const applyVoucher = async (voucher) => {
    if (appliedVouchers.some((el) => el?.voucherId == voucher?.voucherId))
      return;
    let appliedVoucher = voucher;
    if (
      !appliedVoucher.voucher.value &&
      appliedVoucher?.voucher?.type != VoucherType.CASH
    ) {
      appliedVoucher.voucher.value = getSubtotalPrice();
    }
    updateVoucherValue([...appliedVouchers, appliedVoucher]);
  };

  const removeVoucher = (vouchers) => {
    if (vouchers) {
      if (Array.isArray(vouchers)) {
        updateVoucherValue(
          appliedVouchers.filter(
            (el) =>
              !vouchers.some((voucher) => voucher?.voucherId == el?.voucherId)
          )
        );
      } else {
        updateVoucherValue(
          appliedVouchers.filter((el) => vouchers?.voucherId != el?.voucherId)
        );
      }
    } else {
      setVouchers([]);
    }
  };

  const getSubtotalPrice = () => {
    return Math.max(0, subtotalPrice);
  };

  const getTotalPrice = () => {
    let total = promoCode
      ? subtotalPriceWithVoucher - (promoCode?.enjoyedDiscount ?? 0)
      : subtotalPriceWithVoucher;
    return Math.max(0, total);
  };

  // const validateNumOfAttendees = () => {
  //   if (facility?.isHeadCount) {
  //     return numOfAttendees >= 1 && numOfAttendees <= maxAttendeesPerBooking;
  //   } else {
  //     return true;
  //   }
  // };

  const checkIsBookingOwner = (email) => {
    return (
      String(email).toLowerCase() == String(currentUser?.email).toLowerCase()
    );
  };

  const validateKeyMembers = () => {
    if (facility?.requireKeyMembers()) {
      return (
        validateEmailFormat(keyMember1) &&
        validateEmailFormat(keyMember2) &&
        validateEmailFormat(keyMember3) &&
        String(keyMember1).toLowerCase() != String(keyMember2).toLowerCase() &&
        String(keyMember1).toLowerCase() != String(keyMember3).toLowerCase() &&
        String(keyMember2).toLowerCase() != String(keyMember3).toLowerCase() &&
        // Check no equal to current user
        !checkIsBookingOwner(keyMember1) &&
        !checkIsBookingOwner(keyMember2) &&
        !checkIsBookingOwner(keyMember3)
      );
    }
    return true;
  };

  const validateForm = () => {
    return validateKeyMembers();
  };

  const getBookingSummary = () => {
    var bookingSummary = {
      venueName: venue?.getName(),
      items: getBookingDetails(),
      totalPrice: getTotalPrice(),
    };

    if (facility?.isHeadCount()) {
      bookingSummary.numOfAttendees = numOfAttendees;
    }

    if (promoCode) {
      bookingSummary.promoCode = promoCode;
    }

    // TODO: Voucher implementation
    if (appliedVouchers.length > 0) {
      bookingSummary.vouchers = appliedVouchers;
    }

    return {
      bookingSummary,
      payload: getConfirmBookingPayload(),
    };
  };

  const getConfirmBookingPayload = () => {
    var payload = {
      booking_id: bookingIds,
      // totalAmount: getTotalPrice(),
      // attendence: false,
      remarks: facility?.remarks,
      tandc: facility?.tandc,
    };

    if (promoCode) {
      payload.promocode = promoCode.code;
      // payload.promoCodeTicketId = promoCode.promoCodeTicketId;
    }

    // TODO: Voucher implementation
    if (appliedVouchers.length > 0) {
      payload.voucher = appliedVouchers.map((el) => el.voucherId);
    }

    // if (facility?.isHeadCount()) {
    //   payload.attendees = numOfAttendees;
    // }

    if (facility?.isMultiVenueFacility) {
      payload.purpose = selectedPurpose;
    }

    if (facility?.requireKeyMembers()) {
      payload.guestlist = [
        { email: keyMember1, key_member: true },
        { email: keyMember2, key_member: true },
        { email: keyMember3, key_member: true },
      ];
    }

    return payload;
  };

  return {
    // maxAttendeesPerBooking,
    // numOfAttendees,
    keyMember1,
    keyMember2,
    keyMember3,
    appliedVouchers,
    promoCode,
    allowedVoucherTypes,
    getBookingDetails,
    // onPressAddAttendees,
    // onPressSubtractAttendees,
    applyPromoCode,
    removePromoCode,
    applyVoucher,
    removeVoucher,
    getSubtotalPrice,
    getTotalPrice,
    setKeyMember1,
    setKeyMember2,
    setKeyMember3,
    checkIsBookingOwner,
    validateForm,
    getBookingSummary,
  };
};
