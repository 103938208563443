import { DispatchWithoutAction } from "react";
import axios from "axios";
import {
  appearGlobalError,
  disappearGlobalLoading,
} from "common/context/requests/globalRequest";
import { getLocalisedString } from "common/utils/stringHelper";
import { SaiShaAPIResponse, SaiShaPaymentResultBase } from "../@types/common";
import { ROUTE_BASE } from "Route";
import i18n from "i18n";
import clsx, { ArgumentArray } from "classnames";
import { twMerge } from "tailwind-merge";
import { ProgramTypeKeys } from "pageComponents/booking/BookingBase";

export function cn(...inputs: ArgumentArray) {
  return twMerge(clsx(inputs));
}

export const isEmptyValues = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const convertToFormUrlEncoded = (jsonBody: any) => {
  const formBody = Object.keys(jsonBody)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(jsonBody[key])}`
    )
    .join("&");
  return formBody;
};

export function escapeHTMLtoPlainText(htmlStr: string) {
  return htmlStr.replace(/(<([^>]+)>)/gi, "");
}

export const removeHtmlTag = (text: string) => {
  return text ? text.replace(/<\/?[^>]+(>|$)/g, "").replace(/\n/g, "") : "";
};
