const config = {
  HOSTNAME: process.env.REACT_APP_HOSTNAME,
  API_ROOT: process.env.REACT_APP_API_ROOT,
  API_TIMEOUT: parseInt(process.env.REACT_APP_API_TIMEOUT || "180", 10),
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || "", // type safety
  RECAPTCHA_SITE_KEY_CHECKBOX:
    process.env.REACT_APP_RECAPTCHA_SITE_KEY_CHECKBOX === "true" || true, // default use v2 (checkbox)
  HMAC_KEY: process.env.REACT_APP_HMAC_KEY,
  CHATBOT_ROOT: process.env.REACT_APP_CHATBOT_ROOT,
  MONTHLY_SPORTPASS_RENEW_MODLA: parseInt(
    process.env.REACT_APP_MONTHLY_SPORTPASS_RENEW_MODLA || "30"
  ),
  ANNUAL_SPORTPASS_RENEW_MODLA: parseInt(
    process.env.REACT_APP_ANNUAL_SPORTPASS_RENEW_MODLA || "30"
  ),
  NUM_OF_SLOT_CAN_BE_SELECT_AT_ONCE: parseInt(
    process.env.REACT_APP_NUM_OF_SLOT_CAN_BE_SELECT_AT_ONCE || "1"
  ),
  FACILITY_AVAILBLE_DAY: parseInt(
    process.env.REACT_APP_FACILITY_AVAILBLE_DAY || "14"
  ),
  PAYMENT_RETRY_INTERVAL_SECONDS: parseInt(
    process.env.REACT_APP_PAYMENT_RETRY_INTERVAL_SECONDS || "10" // 10 seconds
  ),
  PAYMENT_RETRY_MAX_SECONDS: parseInt(
    process.env.REACT_APP_PAYMENT_RETRY_MAX_SECONDS || "900" // 15 mins
  ),
  HIDE_MONTHLY_PROGRAM: true,
  HIDE_PRIVATE_COACH: true,
  HIDE_VOUCHER: true,
  HIDE_PROMO_CODE: true,
  HIDE_BUNDLE: true,
  HIDE_SPORT_PASS: true,

  APPLICATION_VERSION: "1.5.1",
  APPLICATION_PLATFORM: "web",
};

export default config;
