import { useState, useEffect, useContext } from "react";
import {
  appearAccountVerification,
  appearGlobalError,
} from "../../context/requests/globalRequest";
import { createBooking as createBookingReq } from "../../network/booking";
import { getMPayLanguage } from "../../utils/locale";
import i18n from "i18n";
import { getLocalisedString } from "../../utils/stringHelper";
import { isEmptyValues } from "../../../utils/common";
import { GlobalContextStore } from "../../context/providers/globalProvider";
import { createKioskBooking } from "../../network/kiosk";
import config from "../../../config";
import { usePromoCodeViewModel } from "../Payment/PromoCodeViewModel";
import { PromoCodeBookingType } from "../../models/PromoCode";

export const useFacilityBookingConfirmationViewModel = () => {
  const { globalDispatch } = useContext(GlobalContextStore);
  const { applyPromoCodeDiscount, releasePromoCodeTicket } =
    usePromoCodeViewModel();

  const confirmBooking = async ({ payload, dispatch }) => {
    try {
      const res = await createBooking(payload);
      console.log("Confirm Booking: ", res);
    } catch (e) {
      appearGlobalError(dispatch, e?.response?.data?.msg);
    }
  };

  const createBooking = async (payload) => {
    let promoCodeTicketId = null;
    if (!isEmptyValues(payload?.promocode)) {
      const res = await applyPromoCodeDiscount({
        code: payload?.promocode,
        bookingType: PromoCodeBookingType.FACILITY,
      });
      if (res?.success) {
        promoCodeTicketId = res?.promoCodeTicketId;
        payload.promoCodeTicketId = res?.promoCodeTicketId;
      } else {
        // Apply Promo Code error
        return {
          success: false,
          error: {
            msg: getLocalisedString(
              res?.error?.localizedMessage?.en,
              res?.error?.localizedMessage?.zh,
              true
            ),
          },
          isRedirectHandle: true, // For handle app navigation
        };
      }
    }
    try {
      const res = config.IS_KIOSK
        ? await createKioskBooking({
            ...payload,
            lang: getMPayLanguage(),
            platform: "KIOSK",
            kiosk_id: "K00001",
            type: "FACILITY",
          })
        : await createBookingReq({
            ...payload,
            lang: getMPayLanguage(),
          });

      if (res?.success && res?.result) {
        return res;
      } else {
        throw new Error(
          "Unknown exception. createEventBooking success without booking id"
        );
      }
    } catch (e) {
      console.log("createBookingReq failed. ", e);
      const error = e?.response?.data?.error;
      const errorCode = error?.errorCode;

      if (!isEmptyValues(promoCodeTicketId)) {
        // Manual release promo code ticket
        await releasePromoCodeTicket({ promoCodeTicketId });
      }

      if (errorCode === -1001 || errorCode === -1002) {
        // -1001 for pending, -1002 for new user
        appearAccountVerification(
          globalDispatch,
          errorCode === -1002 ? true : false
        );

        return { success: false, needVerification: true };
      } else {
        const errorMsg = !isEmptyValues(error)
          ? getLocalisedString(
              error?.localizedMessage?.en,
              error?.localizedMessage?.zh,
              true
            )
          : e.message + "";

        let res = {
          success: false,
          error: {
            errorCode: errorCode,
            msg: errorMsg,
          },
        };

        if (errorCode === -302 || errorCode === -303) {
          res.isRedirectHandle = true; // For handle navigation
        }

        return res;
      }
    }
  };

  return {
    confirmBooking,
    createBooking,
  };
};
