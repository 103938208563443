import { ROUTE_BASE } from "Route";
import { MonthlyProgramClass } from "common/models/MonthlyProgram";
import { useContext, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import MonthlyProgramBooking from "./MonthlyProgramBooking";
import { IProgramOutletContextType } from "./MonthlyProgramScreen";
import { IGlobalProviderContext } from "../../@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import _ from "lodash";

const MonthlyProgramBookingScreen = () => {
  const { programId, classId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { programClassList, getClassInfoByProgrammeId } =
    useOutletContext<IProgramOutletContextType>();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );
  const programClass: MonthlyProgramClass | undefined = programClassList.find(
    (programClass) => programClass._id === classId
  );

  if (programClass) {
    programClass.lessons = programClass.lessons.filter(
      (lesson) => lesson.isFuture === true
    );
  }

  useEffect(() => {
    /**not allow deep link */
    if (!programClass) {
      navigate("../");
    }
  }, [programClass]);

  return programClass ? (
    <MonthlyProgramBooking programClass={programClass} />
  ) : (
    <></>
  );
};

export default MonthlyProgramBookingScreen;
