import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import styled from "styled-components";

import { Typography } from "@mui/material";
import MonthlyProgramBanner from "assets/bg-monthly-program-banner.png";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  MonthlyProgramClass,
  ProgramClassStatus,
} from "common/models/MonthlyProgram";
import { getLocalisedString } from "common/utils/stringHelper";
import { useMonthlyProgramViewModel } from "common/viewModels/MonthlyProgramViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import GeneralButton from "components/common/Button";
import Carousel from "components/common/Carousel";
import HTMLTypography from "components/common/HTMLTypography";
import PageContainer from "components/common/PageContainer";
import Space from "components/common/Space";
import ProgramWaitListModal, {
  WaitListModalOpenState,
} from "components/modals/ProgramWaitListModal/ProgramWaitListModal";
import _, { isEmpty } from "lodash";
import { IMonthlyProgrammeViewModel } from "models/Programme";
import ColorBannerCard from "pageComponents/facility/ColorBannerCard";
import { removeHtmlTag } from "utils/common";
import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import { ProgramClassTable } from "./Components/programClassTable/ProgramClassTable";
import SelectOptionsButton from "./Components/programClassTable/SelectOptionsButton";
import { IProgramOutletContextType } from "./MonthlyProgramScreen";
import { ContentWrapper, SelectClassBox, Title } from "./Styled";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";
import { ROUTE_BASE } from "Route";
import { PageSearchParamsKey } from "pages/LoginScreen/LoginScreen";
import useLoginRedirect from "hooks/useLoginRedirect";

interface WaitListModalState {
  classId?: string;
  isOpen: boolean;
  initialOpenState?: WaitListModalOpenState;
}

const MonthlyProgramDetail = () => {
  const { t } = useTranslation("monthlyProgram");
  const location = useLocation();
  const navigate = useNavigate();
  const { programId, id: categoryId } = useParams();
  const [isTipClickedOnce, setIsTipClickedOnce] = useState<boolean>(false);
  const { currentUser, refreshCurrentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);

  const { withAuthCheck } = useLoginRedirect();
  const { getPendingPaymentStatus } =
    useMonthlyProgramViewModel() as IMonthlyProgrammeViewModel;
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );

  const selectOptionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("entries", entry.intersectionRatio);
        // setIsTipClickedOnce(true);
      },
      {
        threshold: 1,
      }
    );
    if (selectOptionRef.current) observer.observe(selectOptionRef.current);
    return () => {
      if (selectOptionRef.current) observer.unobserve(selectOptionRef.current);
    };
  }, [selectOptionRef.current]);

  useEffect(() => {
    getClassInfoByProgrammeId(programId as string);
  }, [currentUser]);

  const {
    programClassList,
    getClassInfoByProgrammeId,
    applyWaitingList,
    dropoutWaitingList,
  } = useOutletContext<IProgramOutletContextType>();

  const [waitListModelOpen, setWaitListModelOpen] =
    useState<WaitListModalState>({
      isOpen: false,
    });

  const handleClickEnroll = async (programClass: MonthlyProgramClass) => {
    const userChecker = await refreshCurrentUser({
      checkIsNewUser: true,
    });
    // const isPendingPayment = await getPendingPaymentStatus(programClass._id);

    // wait_list and already on list not depends on pending payment
    if (!(userChecker?.success && userChecker?.isNewUser === false)) {
      return;
    }
    if (programClass.status === ProgramClassStatus.WAIT_LIST) {
      setWaitListModelOpen({
        classId: programClass._id,
        isOpen: true,
      });
      return;
    } else if (programClass.status === ProgramClassStatus.ALREADY_ON_LIST) {
      setWaitListModelOpen({
        classId: programClass._id,
        isOpen: true,
        initialOpenState: WaitListModalOpenState.REMOVE_FROM_WAITING,
      });
      return;
    } else if (programClass.status === ProgramClassStatus.OPEN_ENROLL) {
      const isPendingPayment = await getPendingPaymentStatus(programClass._id);
      if (isPendingPayment?.success) {
        navigate(
          `../program/${programClass.programme._id}/booking/${programClass._id}`
        );
      }
    }
  };

  const cheapestClassesPrice = () => {
    const price = programClassList.reduce<number>((cheapest, currentClass) => {
      const currentClassPrice =
        currentClass.lessons.filter((lesson) => lesson.isFuture === true)
          .length * currentClass.pricing;
      if (currentClassPrice < cheapest) return currentClassPrice;
      return cheapest;
    }, Number.MAX_SAFE_INTEGER);

    if (price === Number.MAX_SAFE_INTEGER) return null;
    return price;
  };

  // related fields
  const program =
    programId === programClassList[0]?.programme._id &&
    programClassList[0]?.programme;

  const programTitle = program
    ? getLocalisedString(
        programClassList[0].programme.titleEn,
        programClassList[0].programme.titleZh
      )
    : "";

  const programFacilityCategory = program
    ? getLocalisedString(
        program.facility?.category?.name_en,
        program.facility?.category?.name_tc
      )
    : "";

  const programDescription = program
    ? getLocalisedString(program.descriptionEn, program.descriptionZh)
    : "";

  const programRemarks = program
    ? getLocalisedString(program.remarksEn, program.remarksZh)
    : "";

  const getMetaDescription = () => {
    return removeHtmlTag(programDescription);
  };

  return program ? (
    <>
      <Helmet>
        <title>
          {getLocalizedPageTitleWithPrefix({
            en: program?.titleEn,
            tc: program?.titleZh,
          })}
        </title>
        <meta name="description" content={getMetaDescription()} />
      </Helmet>
      <PageContainer
        title={getLocalisedString(program?.titleEn, program?.titleZh)}
        screenHeader={`${t(
          "monthlyProgram:monthlyProgramList.screenHeader"
        )} (${programFacilityCategory})`}
        banner={
          getLocalisedString(program?.bannerEn, program?.bannerZh) ??
          MonthlyProgramBanner
        }
      >
        <ContentWrapper>
          <div className="flex-1">
            <Title>{programTitle}</Title>
            <Space size="20px" />
            {program?.photos?.length > 0 && <Carousel item={program.photos} />}
            <HTMLTypography content={programDescription}></HTMLTypography>
            <Space size="40px" />
            <ColorBannerCardReset
              ref={selectOptionRef}
              id="program_class_table"
              title={t(
                "monthlyProgram:monthlyProgramDetail.titleLessonDetails"
              )}
            >
              {programClassList.map((programClass, idx) => {
                return (
                  <ProgramClassTable
                    key={idx}
                    programClass={programClass}
                    onClickEnroll={withAuthCheck(handleClickEnroll)}
                  />
                );
              })}
            </ColorBannerCardReset>
            <Space size="40px" />

            <div>{programRemarks}</div>
          </div>

          <div className="hidden md:block w-[300px] ml-[67px] lg:w-[357px] ">
            <SelectClassBox>
              <div className="flex justify-center items-center">
                <Typography fontSize="24px" fontWeight="500">
                  {t("monthlyProgramDetail.lowestPriceFrom", {
                    price: cheapestClassesPrice() || "-",
                  })}
                </Typography>
              </div>
              <div className="flex justify-center mt-[30px]">
                <a href="#program_class_table" className="w-100">
                  <GeneralButton style={{ width: "100%" }}>
                    {t("booking:facilityDetail.selectOptions")}
                  </GeneralButton>
                </a>
              </div>
            </SelectClassBox>
          </div>
        </ContentWrapper>

        {!isTipClickedOnce && (
          <SelectOptionsButton
            onEnterClassTable={() => setIsTipClickedOnce(true)}
          />
        )}
        <ProgramWaitListModal
          open={waitListModelOpen.isOpen}
          onClose={() => setWaitListModelOpen({ isOpen: false })}
          initialOpenState={waitListModelOpen.initialOpenState}
          classId={waitListModelOpen.classId}
          onApplyWaitingList={applyWaitingList}
          onDropoutWaitingList={dropoutWaitingList}
        />
      </PageContainer>
    </>
  ) : (
    <PageContainer
      title={t("monthlyProgramList.screenHeader")}
      banner={MonthlyProgramBanner}
    >
      <div>
        <Typography>{t("common:comingSoon")}</Typography>
      </div>
    </PageContainer>
  );
};

const ProgramTitleWithCategory = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  align-self: self-start;
  padding: 30px 50px;
`;

const ColorBannerCardReset = styled(ColorBannerCard)`
  > div:nth-child(2) {
    padding: 0;
  }
`;
export default MonthlyProgramDetail;
