import React, { useState } from "react";
import Styled from "../Styled";
import { ProgramBookingLesson } from "models/Programme";
import cn from "classnames";
import LessonAttendanceItem, {
  LessonAttendanceProps,
} from "components/MyBookingListingCard/MyProgramListingCard/LessonAttendanceItem";
import ProgramSickLeaveModal from "components/modals/ProgramSickLeaveModal/ProgramSickLeaveModal";
import { ProgrammeLessonAttendanceStatus } from "common/models/MonthlyProgram";
import { useTranslation } from "react-i18next";
import { ReactComponent as DocumentSVG } from "../../../assets/document.svg";
import { SaiShaAPIResponse } from "../../../@types/common";

type Props = {
  programLessons: ProgramBookingLesson[];
  requestProgramLessonSickLeave: (
    lessonId: string,
    documentLink?: string
  ) => Promise<SaiShaAPIResponse<object>>;
};

const BookingDetailProgramAttendanceCard = ({
  programLessons,
  requestProgramLessonSickLeave,
}: Props) => {
  const [sickLeaveLesson, setSickLeaveLesson] =
    useState<ProgramBookingLesson | null>(null);

  const { t } = useTranslation("myBooking");

  return (
    <Styled.SharedCard
      className={cn(
        "w-full px-[22px] py-[22px]",
        "lg:px-[37px]",
        "lg:py-[22px]"
      )}
    >
      {programLessons?.map((lessonWithAttendance, idx) => {
        const attendanceProps: LessonAttendanceProps = {
          date: lessonWithAttendance.startTime,
          startTime: lessonWithAttendance.startTime,
          endTime: lessonWithAttendance.endTime,
          status: lessonWithAttendance?.status,
          attendanceStatus: lessonWithAttendance.attendance?.status,
          venues: lessonWithAttendance.venues,
          sickLeave: lessonWithAttendance.attendance?.sickLeave,
          customStatusDisplay: {
            [ProgrammeLessonAttendanceStatus.SickLeave]: (
              <div className="flex flex-col">
                <div className="flex gap-1 items-center">
                  {t("detail.checkedIn")}
                  <DocumentSVG />
                </div>
                <div className="text-[#8C8C8C]">
                  {t("detail.sickLeave.approved")}
                </div>
              </div>
            ),
            [ProgrammeLessonAttendanceStatus.SickLeavePending]:
              !lessonWithAttendance.attendance?.sickLeave?.documentLink ? (
                <>
                  <div>{t("detail.checkedIn")}</div>
                  <div className="text-[#EB4309]">
                    {t("detail.sickLeave.uploadRequired")}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex gap-1 items-center">
                    {t("detail.checkedIn")}
                    <DocumentSVG />
                  </div>
                  {t("detail.sickLeave.uploadSucceed")}
                </>
              ),
          },
        };
        return (
          <>
            <LessonAttendanceItem
              key={lessonWithAttendance._id}
              {...attendanceProps}
              className="mt-[10px] text-sm"
              onRequestSickLeave={() =>
                setSickLeaveLesson(lessonWithAttendance)
              }
            />
            {idx !== programLessons.length - 1 && (
              <div className="w-full h-[10px] border-b border-dashed border-[#D5D5D5]" />
            )}
          </>
        );
      })}
      {sickLeaveLesson && (
        <ProgramSickLeaveModal
          open={!!sickLeaveLesson}
          lesson={sickLeaveLesson}
          onRequestSickLeave={requestProgramLessonSickLeave}
          onClose={() => setSickLeaveLesson(null)}
        />
      )}
    </Styled.SharedCard>
  );
};

export default BookingDetailProgramAttendanceCard;
