import { IUserViewModelContext } from "../@types/common";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { isEmpty } from "lodash";
import { UpcomingBookingType } from "models";
import { PageSearchParamsKey } from "pages/LoginScreen/LoginScreen";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_BASE } from "Route";

const useLoginRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const isUserLoggedIn = !isEmpty(currentUser);

  const navigateToLoginWithSavedRedirect = () => {
    if (!isUserLoggedIn) {
      navigate(
        `/${ROUTE_BASE.AUTH}/sign-in?${
          PageSearchParamsKey.callbackUrl
        }=${encodeURIComponent(location.pathname)}`
      );
      return;
    }
  };

  const withAuthCheck = <T, R>(fn: (params: T) => R) => {
    if (!isUserLoggedIn) {
      return (params: T) => {
        navigateToLoginWithSavedRedirect();
        return fn(params);
      };
    }
    return fn;
  };

  // may parse the url... if want to navigate to any other page

  return {
    navigateToLoginWithSavedRedirect,
    withAuthCheck,
  };
};

export default useLoginRedirect;
