import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import Styled from "./Styled";
import Typography from "components/common/Typography";
import { useTranslation } from "react-i18next";
import { WindowSize } from "utils/responsive";
import IconTick from "../../assets/ic-greenTick.png";
import IconError from "../../assets/ic-cross.png";

type Props = {
  title: string;
  size: number;
  resendText?: string;
  countdownTime: number | undefined;
  otpCorrect: boolean;
  showOtpResult: boolean;
  otp: string;
  disabled: boolean;
  setOtp: (value: string) => void;
  onResendClick?: () => void;
  onOtpComplete: () => void;
};

const OtpValidationCard = ({
  title,
  size,
  resendText,
  countdownTime,
  otpCorrect,
  showOtpResult,
  otp,
  disabled,
  setOtp,
  onResendClick,
  onOtpComplete,
}: Props) => {
  const { t } = useTranslation("register");
  const isMobile = WindowSize() === "mobile";

  // auto submit otp complete if size is reached
  useEffect(() => {
    if (otp.length === size && onOtpComplete != null) {
      onOtpComplete();
    }
  }, [otp, size]);

  return (
    <Styled.OtpCard>
      <Styled.OtpCardTitleText>{title}</Styled.OtpCardTitleText>
      {showOtpResult && (
        <div className="flex justify-center">
          <img
            src={otpCorrect ? IconTick : IconError}
            style={{ height: 14, width: 14, objectFit: "contain" }}
          />
          <Typography
            style={{
              color: otpCorrect ? "#00926F" : "#FF0000",
              fontSize: 14,
              marginLeft: 5,
            }}
          >
            {otpCorrect
              ? t("verification.verified")
              : t("verification.incorrect_otp")}
          </Typography>
        </div>
      )}
      <Styled.OtpInputLabelListContainer>
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={size}
          renderSeparator={<span className="mr-[13px] md:mr-[15px]"></span>}
          inputType="tel"
          inputStyle={{ width: isMobile ? 30 : 36, height: isMobile ? 35 : 41 }}
          renderInput={(props) => <input {...props} />}
        />
      </Styled.OtpInputLabelListContainer>
      {!otpCorrect && (
        <Styled.ResendButtonPosition>
          {countdownTime ? (
            <Typography fontSize="11px">
              {t("button.resend_otp_in", {
                countdown: countdownTime,
              })}
            </Typography>
          ) : (
            <Styled.ResendButton
              type="button"
              onClick={onResendClick}
              disabled={disabled}
            >
              {resendText}
            </Styled.ResendButton>
          )}
        </Styled.ResendButtonPosition>
      )}
    </Styled.OtpCard>
  );
};

export default OtpValidationCard;
