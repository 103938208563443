import { useState } from "react";
import {
  getBanners as getBannersAPI,
  getLatestNews as getLatestNewsAPI,
  getHeadlineNews as getHeadlineNewsAPI,
} from "../network/home";
import { getEventList as getEventListAPI } from "../network/event";
import { appearGlobalError } from "../context/requests/globalRequest";
import {
  listConfirmedFacilityBooking,
  listUpcomingBookingAPI,
} from "../network/booking";
import { listCoachLesson } from "../network/coachLesson";
import { useCommonFetcher } from "./Common/CommonViewModel";

export const useHomeInfoViewModel = (dispatch) => {
  const { fetcher } = useCommonFetcher();
  const [bannerList, setBannerList] = useState([]);
  const [headlineNews, setHeadlineNews] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [latestNewsCount, setLatestNewsCount] = useState(null); // web need this for none item check
  const [coachLessonList, setCoachLessonList] = useState([]);

  const [upcomingBooking, setUpcomingBooking] = useState([]);

  const getHeadlineNews = async (limit = 10) => {
    const res = await fetcher({
      api: () => getHeadlineNewsAPI({ limit }),
      skipErrorHandle: true,
    });

    if (res?.success) {
      setHeadlineNews(res?.result);
    }

    return res;
  };

  const getLatestNews = async (limit = 10, skip = 0) => {
    const res = await fetcher({
      api: () => getLatestNewsAPI({ limit, skip }),
      skipLoading: true,
      skipErrorHandle: true,
    });

    if (res?.success) {
      setLatestNews(res.result);
      setLatestNewsCount(res?.total);
    }

    return res;
  };

  const getFilteredLatestNews = async (keywords, limit = 10, skip = 0) => {
    const res = await fetcher({
      api: () => getLatestNewsAPI({ title: keywords, limit, skip }),
      skipErrorHandle: true,
    });

    if (res?.success) {
      setLatestNews(res.result);
      setLatestNewsCount(res?.total);
    }

    return res;
  };

  const getCoachLessonList = async () => {
    const res = await fetcher({ api: listCoachLesson, skipErrorHandle: true });
    if (res?.success) {
      setCoachLessonList(res);
    }

    return res;
  };

  const getUpcomingBookingList = async (limit = 3, skip = 0) => {
    const res = await fetcher({
      api: () => listUpcomingBookingAPI({ limit, skip }),
      skipErrorHandle: true,
    });
    if (res?.success) {
      setUpcomingBooking(res?.result);
    }

    return res;
  };

  const getBannerList = async () => {
    const res = await fetcher({
      api: () => getBannersAPI(),
      skipErrorHandle: true,
    });
    if (res?.success) {
      setBannerList(res?.result);
    }

    return res;
  };

  return {
    bannerList,
    headlineNews,
    latestNews,
    latestNewsCount,
    coachLessonList,
    upcomingBooking,
    getBannerList,
    getHeadlineNews,
    getLatestNews,
    getFilteredLatestNews,
    getCoachLessonList,
    getUpcomingBookingList,
  };
};
