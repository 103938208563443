import { useCommonFetcher } from "../Common/CommonViewModel";
import {
  requestPhoneNumberOTP as requestPhoneNumberOTPApi,
  verifyPhoneNumberOTP as verifyPhoneNumberOTPAPI,
} from "../../network/verification";
import { MobileVerificationResult } from "../../models/Verification";
import { useEffect, useMemo, useState } from "react";
import { useCountDownViewModel } from "../Common/CountDownViewModel";
import { getE164Format } from "../../utils/phoneHelper";
import { isEmptyValues } from "../../utils/common";
import { CountryOption } from "../../models/CountryOption";
import { getCountryOptionFromCountryCode } from "../../utils/countryHelper";
import { updateMobile } from "../../network/profile";
import { CountryCode, getCountryCallingCode } from "libphonenumber-js";
import i18n from "../../../i18n";
import CryptoEs from "crypto-es";
import dayjs from "dayjs";
import config from "../../../config";

const defaultRegion = "+852";

/**
 * @param allowByPassVerification - Allow to bypass verification
 * @param executeRecaptcha - Execute recaptcha with action, required if using score based recaptcha
 */
interface ViewmodelProps {
  allowByPassVerification: boolean;
  executeRecaptcha: ((action?: string) => Promise<string>) | undefined
}

export const useMobileVerificationViewModel = (
  props: ViewmodelProps = {
    allowByPassVerification: false,
    executeRecaptcha: async () => "",
  }
) => {
  const { allowByPassVerification } = props;
  const { fetcher } = useCommonFetcher();
  const [country, setCountry] = useState<string>(defaultRegion);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [byPassVerification, setByPassVerification] = useState<boolean>(false);

  const [otp, setOtp] = useState<string>("");
  const [isShowOtpCard, setIsShowOtpCard] = useState<boolean>(false);
  const [otpCorrect, setOtpCorrect] = useState<boolean>(false);
  const [showOtpResult, setShowOtpResult] = useState<boolean>(false);
  const [otpVerificationResult, setOtpVerificationResult] =
    useState<MobileVerificationResult | null>(null);
  const [otpError, setOtpError] = useState<string>("");
  const resendOTPCountdown = useCountDownViewModel();

  const phoneNumInE164Format = useMemo(() => {
    return getE164Format(phoneNumber, country);
  }, [phoneNumber, country]);

  const isFormValid = useMemo(() => {
    const isPhoneNumberInputed =
      !isEmptyValues(phoneNumber) && !isEmptyValues(country);

    if (allowByPassVerification && byPassVerification) {
      return isPhoneNumberInputed;
    } else {
      return otpCorrect && !isEmptyValues(otpVerificationResult?.otpId);
    }
  }, [byPassVerification, phoneNumber, country, otpVerificationResult]);

  // Hide Otp card & reset otp state on phone number changed
  useEffect(() => {
    setIsShowOtpCard(false);
    clearOtpInput();
  }, [phoneNumber, country]);

  useEffect(() => {
    if (byPassVerification) {
      setIsShowOtpCard(false);
      clearOtpInput();
    }
  }, [byPassVerification]);

  /**
   * 
   * @param generatedToken - Required if using checkbox recaptcha
   */
  const requestPhoneNumberOTP = async (generatedToken?: string | undefined) => {
    let token: string | undefined = generatedToken;
    if (props.executeRecaptcha && !generatedToken) {
      token = await props.executeRecaptcha("request_otp");
    }

    const timestamp = dayjs().valueOf();
    const nonce = CryptoEs.HmacSHA256(
      `${phoneNumInE164Format}:${timestamp}`,
      config.HMAC_KEY!
    ).toString();

    
    const res = await fetcher({
      api: () =>
        requestPhoneNumberOTPApi({
          mobile: phoneNumInE164Format,
          lang: i18n.language === "zh" ? "tc" : "en",
          token,
          nonce,
          timestamp,
        }),
    });

    if (res.success) {
      setIsShowOtpCard(true);
      resendOTPCountdown.startCountDown();
    }
  };

  const getUnverifiedPhoneNumberPayload = () => {
    return {
      dialCode: country,
      phoneNum: phoneNumber,
      parsedPhoneNum: phoneNumInE164Format,
      skipPhoneVerification: true,
    };
  };

  const verifyPhoneNumberOTP = async () => {
    const res = await fetcher({
      api: () =>
        verifyPhoneNumberOTPAPI({
          mobile: phoneNumInE164Format,
          otp,
        }),
    });

    if (res?.success && !isEmptyValues(res?.result?.id)) {
      setOtpCorrect(true);
      setShowOtpResult(true);
      setOtpVerificationResult({
        dialCode: country,
        phoneNum: phoneNumber,
        parsedPhoneNum: phoneNumInE164Format,
        otpId: res?.result?.id,
        skipPhoneVerification: false,
      });
    } else {
      setOtpCorrect(false);
      setShowOtpResult(true);
    }
  };

  const clearOtpInput = () => {
    setOtp("");
    setShowOtpResult(false);
    setOtpCorrect(false);
    setOtpVerificationResult(null);
  };

  const submitUpdateMobile = async () => {
    const res = await fetcher({
      api: () =>
        updateMobile({
          mobile: otpVerificationResult?.parsedPhoneNum, // E.164 Format
          otp_id: otpVerificationResult?.otpId,
        }),
    });

    return res;
  };

  return {
    countdownTime: resendOTPCountdown.timeLeft,
    isFormValid,
    phoneNumber,
    country,
    byPassVerification,
    otp,
    isShowOtpCard,
    otpCorrect,
    showOtpResult,
    otpVerificationResult,
    setByPassVerification,
    setCountry,
    setPhoneNumber,
    setOtp,
    clearOtpInput,
    requestPhoneNumberOTP,
    verifyPhoneNumberOTP,
    submitUpdateMobile,
    getUnverifiedPhoneNumberPayload,
  };
};
