import Typography from "components/common/Typography";
import Styled from "./Styled";
import { useTranslation } from "react-i18next";
import ColorBannerCard from "pageComponents/facility/ColorBannerCard";
import theme from "utils/theme";
import Section from "./Section";
import GeneralButton from "components/common/Button";
import BookingStep from "./BookingStep";
import Space from "components/common/Space";
import AttendessSelect from "./AttendessSelect";
import { useState } from "react";
import CommonTextfield from "components/common/Textfield";
import Receipt from "./Receipt";
import { TAndC } from "models";
import TncLabel from "../../components/TncLabel";

import { ProgramTypes } from "common/viewModels/MyBooking/MyBookingListViewModel";
import TncCheckbox from "components/common/checkbox/TncCheckbox";
import classNames from "classnames";

export type ProgramTypeKeys = keyof typeof ProgramTypes;

interface BookingBaseProps {
  orderInfoComponent: React.ReactNode;
  children?: React.ReactNode;
  remarks?: string;
  tnc?: TAndC;
  isRequireGeneralTncConfirm?: boolean;
  totalAmount?: number;
  receiptComponent?: React.ReactNode;
  receiptPolicyRemark?: string;
  handlePayment: () => Promise<void>;
  programTypes: ProgramTypeKeys;
  disabled?: boolean;
}

const BookingBase = ({
  orderInfoComponent,
  children,
  remarks,
  tnc,
  isRequireGeneralTncConfirm,
  totalAmount,
  receiptComponent,
  receiptPolicyRemark,
  handlePayment,
  programTypes,
  disabled,
}: BookingBaseProps) => {
  const { t } = useTranslation("booking");

  const [isPaymentProceed, setPaymentProceed] = useState(false);

  const [isAcceptTnc, setAcceptTnc] = useState(
    isRequireGeneralTncConfirm ? false : true
  );

  const handleClickPaymentButton = () => {
    setPaymentProceed(true);
    handlePayment().finally(() => setPaymentProceed(false));
  };

  const getNoteText = () => {
    switch (programTypes) {
      case ProgramTypes.Coach:
        return t("bookingDetail.fillGuestEmailLateNoteText");
      case ProgramTypes.Facility:
      case ProgramTypes.Event:
        return t("bookingDetail.noteText");
      default:
        break;
    }
  };

  return (
    <div>
      <BookingStep />
      <Space size="26px" />

      <Styled.GreyContainer>
        <Typography fontSize="30px" fontWeight={500}>
          {t("bookingDetail.headerTitle")}
        </Typography>
        <Space size="40px" />
        <div className="flex flex-col md:flex-row">
          <div className="w-full flex flex-col">
            <ColorBannerCard title={t("bookingDetail.bookingInfo")}>
              {programTypes !== ProgramTypes.Program && (
                <Typography color={theme.lightGrey}>{getNoteText()}</Typography>
              )}

              <Section title={t("bookingDetail.bookingInfo")}>
                {orderInfoComponent}
              </Section>

              {children}

              {tnc && (
                <TncLabel
                  className={classNames("mt-[50px]", {
                    "mb-4": isRequireGeneralTncConfirm,
                  })}
                  data={tnc}
                />
              )}
              {isRequireGeneralTncConfirm && (
                <div className={classNames("ml-[-9px]", { "mt-[50px]": !tnc })}>
                  <TncCheckbox
                    id="tnc"
                    name="tnc"
                    value={isAcceptTnc}
                    onChange={() => setAcceptTnc((prev) => !prev)}
                    data={tnc}
                  />
                </div>
              )}

              {remarks && (
                <div className="mt-[25px]">
                  <Typography fontSize="14px">
                    {t("bookingDetail.remarkText")}
                  </Typography>
                  <Typography fontSize="14px">{remarks}</Typography>
                </div>
              )}
              {/* Divider */}
              <div className="md:hidden">
                <Receipt
                  totalAmount={totalAmount}
                  receiptComponent={receiptComponent}
                  handlePayment={handlePayment}
                />
                {receiptPolicyRemark && (
                  <Typography color={theme.orange} style={{ marginTop: 15 }}>
                    {receiptPolicyRemark}
                  </Typography>
                )}
              </div>

              <div className="flex justify-start mt-[30px] md:mt-[60px] md:justify-end">
                <GeneralButton
                  width="150px"
                  onClick={handleClickPaymentButton}
                  disabled={
                    disabled ||
                    isPaymentProceed ||
                    (isRequireGeneralTncConfirm && !isAcceptTnc)
                  }
                >
                  {t("bookingDetail.payment")}
                </GeneralButton>
              </div>
            </ColorBannerCard>
          </div>
          <div className="w-[70px]" />
          {/* Desktop view render */}
          <div className="w-[300px] lg:w-[357px] shrink-0 hidden md:block">
            <Receipt
              totalAmount={totalAmount}
              receiptComponent={receiptComponent}
              handlePayment={handlePayment}
              disabledPayment={
                disabled || (isRequireGeneralTncConfirm && !isAcceptTnc)
              }
            />
            {receiptPolicyRemark && (
              <Typography color={theme.orange} style={{ marginTop: 15 }}>
                {receiptPolicyRemark}
              </Typography>
            )}
          </div>
        </div>
      </Styled.GreyContainer>
    </div>
  );
};

export default BookingBase;
