import i18n from "i18n";
import {
  createClassEnrollment,
  createClassPayment,
} from "../../network/monthlyProgram";
import { getMPayLanguage } from "../../utils/locale";
import { getLocalisedString } from "../../utils/stringHelper";
import { isEmptyValues } from "../../../utils/common";
import { appearAccountVerification } from "../../context/requests/globalRequest";
import { useContext } from "react";
import { GlobalContextStore } from "../../context/providers/globalProvider";
import config from "../../../config";
import { createKioskBooking } from "../../network/kiosk";
import { usePromoCodeViewModel } from "../Payment/PromoCodeViewModel";
import { PromoCodeBookingType } from "../../models/PromoCode";

export const useMonthlyProgramBookingConfirmationViewModel = () => {
  const { globalDispatch } = useContext(GlobalContextStore);
  const { applyPromoCodeDiscount, releasePromoCodeTicket } =
    usePromoCodeViewModel();

  const confirmBooking = async (payload) => {
    let promoCodeTicketId = null;
    if (!isEmptyValues(payload?.promocode)) {
      // Apply promo code
      const res = await applyPromoCodeDiscount({
        code: payload?.promocode,
        bookingType: PromoCodeBookingType.PROGRAMME,
      });
      if (res?.success) {
        promoCodeTicketId = res?.promoCodeTicketId;
        payload.promoCodeTicket = res?.promoCodeTicketId;
      } else {
        // Apply Promo Code error
        return {
          success: false,
          error: getLocalisedString(
            res?.error?.localizedMessage?.en,
            res?.error?.localizedMessage?.zh,
            true
          ),
          isRedirectHandle: true, // For handle navigation
        };
      }
    }
    try {
      const res = config.IS_KIOSK
        ? await createKioskBooking({
            ...payload,
            lang: getMPayLanguage(),
            type: "PROGRAM",
          })
        : await createClassEnrollment({
            ...payload,
            lang: getMPayLanguage(),
          });
      if (res?.success) {
        return res;
      } else {
        throw new Error(
          "Unknown exception. create program Booking success without booking id"
        );
      }
    } catch (e) {
      console.log("create program Booking fail. Error:", e);
      const error = e?.response?.data?.error;
      const errorCode = error?.errorCode;

      if (!isEmptyValues(promoCodeTicketId)) {
        // Manual release promo code ticket
        await releasePromoCodeTicket({ promoCodeTicketId });
      }

      if (errorCode === -1001 || errorCode === -1002) {
        // -1001 for pending, -1002 for new user
        appearAccountVerification(
          globalDispatch,
          errorCode === -1002 ? true : false
        );
        return { success: false, needVerification: true };
      } else {
        const errorMsg = !isEmptyValues(error)
          ? getLocalisedString(
              error?.localizedMessage?.en,
              error?.localizedMessage?.zh,
              true
            )
          : e.message + "";

        let res = {
          success: false,
          error: errorMsg,
        };

        if (errorCode === -2001 || errorCode === -2002) {
          res.isRedirectHandle = true; // For handle navigation
        }

        return res;
      }
    }
  };

  return {
    confirmBooking,
  };
};
