import { updateProfile, removeGuardian, verifyUser } from "../../network/profile";
import { useContext } from "react";
import { UserViewModelContext } from "../UserViewModel";
import { appearGlobalError, appearGlobalLoading, disappearGlobalLoading } from "../../context/requests/globalRequest";
import { getLocalisedString } from "../../utils/stringHelper";
import { isEmptyValues } from "utils/common";
import { getUserWithToken } from "common/network/user";

export const useProfileInfoViewModel = () => {
  const { refreshCurrentUser } = useContext(UserViewModelContext);
  const editProfileInfo = async (payload, dispatch) => {
    try {
      appearGlobalLoading(dispatch);
      const profileRes = await updateProfile(payload);
      if (profileRes.success) {
        const userRes = await refreshCurrentUser();
        disappearGlobalLoading(dispatch);
        return userRes;
      }
    } catch (e) {
      console.log("edit profile error:", e)
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  };

  const verifyUserInfo = async (payload, dispatch, token) => {
    try {
      appearGlobalLoading(dispatch);
      const res = await verifyUser(payload, token);
      if (res.success) {
        disappearGlobalLoading(dispatch);
        return res;
      }
    } catch (e) {
      console.log("verify user error:", e)
      const error = e?.response?.data?.error;
      const errorMsg = !isEmptyValues(error) 
      ? getLocalisedString(error?.localizedMessage?.en, error?.localizedMessage?.zh, true) 
      : e.message + "";

      if(token && e.response.status !== 401){
        appearGlobalError(dispatch, errorMsg);
      }

      disappearGlobalLoading(dispatch);
      return {success: false, isTokenExpired: token && e.response.status === 401};
    }
  }

  const getUserInfoWithToken = async (token, dispatch) => {
    try {
      appearGlobalLoading(dispatch);
      const res = await getUserWithToken(token);
      if (res.success) {
        disappearGlobalLoading(dispatch);
        return res;
      }
    } catch (e) {
      console.log("get user info with token error:", e)
      const error = e?.response?.data?.error;
      const errorMsg = !isEmptyValues(error) 
      ? getLocalisedString(error?.localizedMessage?.en, error?.localizedMessage?.zh, true) 
      : e.message + "";

      if(token && e.response.status !== 401){
        appearGlobalError(dispatch, errorMsg);
      }

      disappearGlobalLoading(dispatch);
      return {success: false, isTokenExpired: token && e.response.status === 401};
    }
  }

  const deleteGuardian = async (dispatch) => {
    try {
      appearGlobalLoading(dispatch);
      const guardianRes = await removeGuardian();
      if (guardianRes.success) {
        const userRes = await refreshCurrentUser();
        disappearGlobalLoading(dispatch);
        return userRes;
      }
    } catch (e) {
      console.log("delete guardian error:", e)
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  };

  return {
    editProfileInfo,
    deleteGuardian,
    verifyUserInfo,
    getUserInfoWithToken,
  };
};
