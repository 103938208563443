export enum UpcomingBookingType {
  FACILITY = "FACILITY",
  COACH_LESSON = "COACH_LESSON",
  EVENT = "EVENT",
  MONTHLY_PROGRAM = "MONTHLY_PROGRAM",
}

export type BookingParticipantInfo = {
  participantName: string;
  age: number | null;
};
